
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import AccessMasterCard from "../../Components/AccessMaster/AccessMasterCard";

//FUNCTION
const AccessMaster = () => {
    //#region code
    const { user_id, is_super, permissions } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [openDialog, setOpenDialog] = useState(true);

    const handleClickOpen = () => {
        setOpenDialog(!openDialog);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
    //#endregion

    return (
        <>
            <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'ACCESS MASTER'} handlePopup={handleClickOpen} create={openDialog} />

            <AccessMasterCard handleClose={handleClose} open={openDialog} />
        </>
    )
}

export default AccessMaster;