import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lightMode: false,
    noRoomService: '',
    id: null,
    name: null,
    address: null,
    company_id: null,
    companyName: null,
    companyAddress: null,
    companyCountry: null,
    companyState: null,
    user_id: null,
    user_name: null,
    email: null,
    currency: null,
    is_super: null,
    encoded_data: null,
    isLogin: false,
    showFab: true,
    invoiceList: [],
}

export const GlobalVariableSlice = createSlice({
    name: 'GlobalVariables',
    initialState,
    reducers: {
        changeLightMode: (state) => {
            state.lightMode = !state.lightMode;
        },
        changeNoRoomService: (state, action) => {
            state.noRoomService = action.payload;
        },
        rSetId: (state, action) => {
            state.id = action.payload;
        },
        rSetName: (state, action) => {
            state.name = action.payload;
        },
        rSetAddress: (state, action) => {
            state.address = action.payload;
        },
        rSetCompanyId: (state, action) => {
            state.company_id = action.payload;
        },
        rSetCompanyName: (state, action) => {
            state.companyName = action.payload;
        },
        rSetCompanyAddress: (state, action) => {
            state.companyAddress = action.payload;
        },
        rSetCompanyCountry: (state, action) => {
            state.companyCountry = action.payload;
        },
        rSetCompanyState: (state, action) => {
            state.companyState = action.payload;
        },
        rSetUserId: (state, action) => {
            state.user_id = action.payload;
        },
        rSetUserName: (state, action) => {
            state.user_name = action.payload;
        },
        rSetEmail: (state, action) => {
            state.email = action.payload;
        },
        rSetCurrency: (state, action) => {
            state.currency = action.payload;
        },
        rSetIsSuper: (state, action) => {
            state.is_super = action.payload;
        },
        rSetEncodedData: (state, action) => {
            state.encoded_data = action.payload;
        },
        rSetLogin: (state, action) => {
            state.isLogin = action.payload;
        },
        rSetShowFab: (state, action) => {
            state.showFab = action.payload;
        },
        rSetInvoiceList: (state, action) => {
            state.invoiceList = action.payload;
        },
    }
});

export const { 
    changeLightMode, 
    changeNoRoomService, 
    rSetId, 
    rSetName, 
    rSetAddress, 
    rSetCompanyId, 
    rSetCompanyName,
    rSetCompanyAddress,
    rSetCompanyCountry,
    rSetCompanyState,
    rSetUserId, 
    rSetUserName,
    rSetEmail,
    rSetCurrency, 
    rSetIsSuper, 
    // rSetToken, 
    rSetEncodedData,
    rSetLogin,
    rSetShowFab,
    rSetInvoiceList,
 } = GlobalVariableSlice.actions;

 export default GlobalVariableSlice.reducer;
