import React, { memo, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  Grid,
  FormControl,
  NativeSelect,
  Select,
  InputLabel,
  Typography,
  IconButton,
  Stack,
  TableSortLabel,
  Button,
  TextField,
  Menu,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";

import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import "../Style.css";
import SearchBox from "../Search/SearchBox";
import CheckAndReturn from "../../utility/CheckAndReturn";
import configServ from "../../services/config";
import { useSelector } from "react-redux";
import useDebounce from "../hooks/useDebounce";
import { useReactToPrint } from "react-to-print";
import DownloadGstInvoiceReport from "../Download/DownloadGstInvoiceReport";

import { useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import useDeviceDetect from "../Detect/useDeviceDetect ";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import SaveIcon from "@mui/icons-material/Save";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";

const GstInvoiceReport = ({ showActionButton = false, row }) => {
  const location = useLocation();
  const { isMobile } = useDeviceDetect();

  // Determine if the current page is GstTransactionReport
  const isGstTransactionPage =
    location.pathname === "/transaction/Gst-transaction-report";

  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const [count, setCount] = useState(0);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [searchBy, setSearchBy] = useState("fname");
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invoiceList, setInvoiceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [filteredReport, setFilteredReport] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [showUnpaidOnly, setShowUnpaidOnly] = useState(true);

  const dateRef = useRef(null);
  const remarksRef = useRef(null);
  const fetchGstInvoiceReport = async () => {
    try {
      setLoading(true);
      const dataToSend = {
        company_id: company_id,
        search: search,
        page: page,
        per_page: rowsPerPage,
        order_by: orderBy,
        order: order,
        start_date: startDate,
        end_date: endDate,
        unpaid: showUnpaidOnly, // Use the state variable here
      };
      const res = await configServ.getGstInvoiceReport(dataToSend);
      console.log("gstInvoice=", res);
      if (res.status === 200) {
        const data = res.data.map((item) => ({
          id: item.id,
          is_gst_paid: item.is_gst_paid,
          gstin: item.gstin,
          fname: item.fname,
          invoice_number: item.invoice_number,
          date: CheckAndReturn.transformToDate3(item.date),
          total_amount: CheckAndReturn.roundToInteger(item.total_amount),
          taxable_amt: CheckAndReturn.roundToInteger(item.taxable_amt),

          cgst:
            item.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2
              : 0,
          sgst:
            item.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2
              : 0,
          igst:
            item.tax_type === "IGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount)
              : 0,
          paid: item.paid || false,
        }));
        setInvoiceList(data);
        setCount(res.total);
      } else {
        setInvoiceList([]);
        setCount(0);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGstInvoiceReport();
  }, [page, rowsPerPage, order, orderBy, startDate, endDate, showUnpaidOnly]);

  useEffect(() => {
    if (page === 0) {
      fetchGstInvoiceReport();
    } else {
      setPage(0);
    }
  }, [debounceSearch]);

  const headers = [
    { id: "gstin", label: "GSTIN" },
    { id: "fname", label: "PARTY NAME" },
    { id: "invoice_number", label: "BILL NO" },
    { id: "date", label: "DATE" },
    { id: "total_amount", label: "INVOICE VALUE" },
    { id: "taxable_amt", label: "TAXABLE AMOUNT" },
    { id: "Total_TaxAmount", label: "CGST" },
    { id: "Total_TaxAmount", label: "SGST" },
    { id: "Total_TaxAmount", label: "IGST" },
    { id: "Total_TaxAmount", label: "TOTAL TAX AMOUNT" },
    // Conditionally add the Action column
    ...(isGstTransactionPage ? [{ id: "action", label: "Paid" }] : []),
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  //   action button
  const handleCheckboxChange = (invoiceId) => {
    setSelectedInvoices((prevSelectedInvoices) => {
      if (prevSelectedInvoices.includes(invoiceId)) {
        // If already selected, remove from the list
        return prevSelectedInvoices.filter((id) => id !== invoiceId);
      } else {
        // If not selected, add to the list
        return [...prevSelectedInvoices, invoiceId];
      }
    });
  };

  // Function to handle select all or deselect all
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedInvoices([]); // Deselect all
    } else {
      setSelectedInvoices(invoiceList.map((invoice) => invoice.id));
    }
    setSelectAll(!selectAll); // Toggle select all state
  };

  // Model Started
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClickOpen = () => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, open the dialog box
        // Swal.fire("Saved!", "", "success");
        setOpen(true); // This will open the dialog box
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  // model end

  const handleSaveChanges = async () => {
    const dateValue = dateRef.current.value;
    // Check if the date is filled
    if (!dateValue) {
      toast.error("Please select a date before submitting.");
      return; // Stop the function execution if the date is not provided
    }
    const remarksValue = remarksRef.current.value;

    const updates = selectedInvoices
      .map((invoiceNumber) => {
        const currentInvoice = invoiceList.find(
          (invoice) => invoice.id === invoiceNumber
        );

        if (currentInvoice) {
          return {
            id: currentInvoice.id, // Assuming invoice_number is the ID you want to use
            is_gst_paid: !currentInvoice.paid, // Toggle the 'paid' status
            gst_paid_on: dateValue, // Set to null since the field is not available
            gst_paid_remark: remarksValue || null, // Set to null since the field is not available
          };
        }
        return null;
      })
      .filter((update) => update !== null); // Filter out any null entries

    if (updates.length > 0) {
      console.log("Saving the following updates:", updates);

      try {
        setLoading(true);

        const res = await configServ.createInvoiceGst(updates);

        if (res.status === 200) {
          toast.success("Invoice updates sent successfully!");
          console.log("Invoice updates sent successfully", res.data);

          fetchGstInvoiceReport();
          handleClose(); // Close the dialog after successful submission
          // Handle success (e.g., update UI, notify user)
        } else {
          toast.error("An error occurred while updating the invoice.");
          console.error("Failed to update invoice", res);
          // Handle error response (e.g., show error message)
        }
      } catch (e) {
        console.error("An error occurred while updating the invoice", e);
        // Handle any exceptions (e.g., network issues)
      } finally {
        setLoading(false);
      }

      // Clear selections after saving
      setSelectAll(false); // Reset select all state
    } else {
      toast.error("No valid invoices selected.");
      console.log("No valid invoices selected.");
    }
  };

  //   ending of action button

  const handleDownloadData = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search: filteredReport === false ? null : search,
        page: filteredReport === false ? null : page,
        per_page: filteredReport === false ? null : rowsPerPage,
        order_by: filteredReport === false ? "date" : orderBy,
        order: filteredReport === false ? "asc" : order,
        start_date: filteredReport === false ? null : startDate,
        end_date: filteredReport === false ? null : endDate,
      };
      const res = await configServ.getGstInvoiceReport(dataToSend);
      if (res.status === 200) {
        const data = res.data.map((item) => ({
          gstin: item.gstin,
          fname: item.fname,
          invoice_number: item.invoice_number,
          date: CheckAndReturn.transformToDate3(item.date),
          total_amount: CheckAndReturn.roundToInteger(item.total_amount),
          taxable_amt: CheckAndReturn.roundToInteger(item.taxable_amt),
          cgst:
            item.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2
              : 0,
          sgst:
            item.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2
              : 0,
          igst:
            item.tax_type === "IGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount)
              : 0,
        }));
        await DownloadGstInvoiceReport(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearchBy = (event) => {
    setSearchBy(event.target.value);
  };

  const gstInvoiceReportRef = useRef();

  const handlePrintData = () => {
    setIsPrint(true);
  };

  useEffect(() => {
    if (isPrint === true) {
      handleTablePrint();
      setIsPrint(false);
    }
  }, [isPrint]);

  const handleTablePrint = useReactToPrint({
    content: () => gstInvoiceReportRef.current,
    documentTitle: "Gst Invoice Report",
  });

  const handleStartDate = (event) => {
    try {
      const date = event.target.value;
      setStartDate(date);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEndDate = (event) => {
    try {
      const date = event.target.value;
      setEndDate(date);
    } catch (e) {
      console.log(e);
    }
  };

  //#endregion

  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={12}
        justifyContent={"center"}
        sx={{ mt: 1 }}
      >
        <Typography
          sx={{ fontSize: "22px", fontWeight: "bold", color: "#1e61b4" }}
        >
          {isGstTransactionPage ? "Gst Transaction" : "GST Invoice Report"}
        </Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent={{ xs: "center", md: "space-between" }}
        spacing={2}
        direction={{ xs: "column", md: "row" }}
      >
        <Grid
          item
          xs={12}
          md={2}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Stack
            direction="row"
            spacing={1}
            justifyContent={{ xs: "center", md: "flex-start" }}
            alignItems="center"
          >
            <Button
              variant="contained"
              onClick={handleDownloadClick}
              startIcon={<DownloadIcon />}
              title="Download"
              sx={{
                backgroundColor: "#4bcf6b",
                color: "white",
                "&:hover": {
                  backgroundColor: "#00b92f",
                  color: "white",
                },
                width: { xs: "100%", md: "auto" }, // Full width on small devices
              }}
            />
            <Button
              variant="contained"
              onClick={handlePrintData}
              startIcon={<PrintIcon />}
              title="Print"
              sx={{
                backgroundColor: "#349af1",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0868F8",
                  color: "white",
                },
                width: { xs: "100%", md: "auto" }, // Full width on small devices
              }}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleDownloadClose}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filteredReport}
                      onClick={() => setFilteredReport(!filteredReport)}
                    />
                  }
                  label="With Filter"
                />
              </MenuItem>
              <MenuItem onClick={handleDownloadData}>Download Excel</MenuItem>
            </Menu>

            {isGstTransactionPage && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleClickOpen}
                  sx={{ mt: { xs: 2, sm: 0 } }}
                />
              </Stack>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={showUnpaidOnly}
                  onChange={(e) => setShowUnpaidOnly(e.target.checked)}
                />
              }
              label="Unpaid"
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={3}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <TextField
              size="small"
              type="date"
              label="From"
              InputLabelProps={{ shrink: true }}
              onChange={handleStartDate}
              fullWidth
              sx={{
                mr: { md: 2 },
              }}
            />
            <TextField
              size="small"
              type="date"
              label="To"
              InputLabelProps={{ shrink: true }}
              onChange={handleEndDate}
              fullWidth
              sx={{
                mt: { xs: 2, md: 0 },
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
            <FormControl fullWidth>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native"
                sx={{
                  width: { xs: "100%", md: "auto" }, // Full width on small devices
                }}
              >
                Search By
              </InputLabel>
              <NativeSelect
                value={searchBy}
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
                sx={{
                  width: { xs: "100%", md: "auto" }, // Full width on small devices
                }}
              >
                <option value={"fname"}>Customer</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} sx={{ mr: { lg: 5 } }}>
          <Stack
            direction={{ lg: "row", xs: "column", md: "row" }}
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <SearchBox
              search={search}
              setSearch={setSearch}
              sx={{
                width: { xs: "100%", md: "auto", lg: "100px" }, // Full width on small devices
              }}
            />
            {isGstTransactionPage && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                }
                label="All paid"
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      {isMobile ? (
        <>
          <Grid container alignItems={"center"} spacing={1} mt={1}>
            <Grid container item xs={6} md={6} justifyContent={"center"}>
              <FormControl fullWidth size="small">
                <InputLabel>Sort By</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  name="order_by"
                  label="Sort By"
                  value={orderBy}
                  onChange={(e) => setOrderBy(e.target.value)}
                  sx={{ background: "white" }}
                >
                  {headers.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={6} md={6} justifyContent={"center"}>
              <FormControl fullWidth size="small">
                <InputLabel>Order</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  name="order"
                  label="Order"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                  sx={{ background: "white" }}
                >
                  <MenuItem key={1} value={"asc"}>
                    {"Ascending"}
                  </MenuItem>
                  <MenuItem key={2} value={"desc"}>
                    {"Descending"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>{" "}
          <Grid container spacing={2} mt={1}>
            {loading ? (
              <Grid item xs={12} textAlign="center">
                <CircularProgress />
              </Grid>
            ) : invoiceList.length > 0 ? (
              invoiceList.map((row, rowIndex) => (
                <Grid item xs={12} key={rowIndex}>
                  <Card variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6">
                        S.No. {page * rowsPerPage + rowIndex + 1}
                      </Typography>
                      <Typography variant="body1">
                        <strong>GSTIN:</strong> {row.gstin}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Party Name:</strong> {row.fname}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Bill No:</strong> {row.invoice_number}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Date:</strong> {row.date}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Invoice Value:</strong> ₹{row.total_amount}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Taxable Value:</strong> ₹{row.taxable_amt}
                      </Typography>
                      <Typography variant="body1">
                        <strong>CGST:</strong> {row.cgst}
                      </Typography>
                      <Typography variant="body1">
                        <strong>SGST:</strong> {row.sgst}
                      </Typography>
                      <Typography variant="body1">
                        <strong>IGST:</strong> {row.igst}
                      </Typography>
                      <Typography variant="body1">
                        <strong>TOTAL TAX AMOUNT</strong>{" "}
                        {row.cgst + row.sgst + row.igst}
                      </Typography>
                    </CardContent>
                    {/* {showActionButton && (
                      <CardActions>
                        <Tooltip title={paymentStatus}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={paymentStatus === "Paid"}
                                onChange={handlePaymentChange}
                                color="primary" // You can change this to success or error based on your design preference
                              />
                            }
                            label={paymentStatus}
                          />
                        </Tooltip>
                      </CardActions>

                    )} */}
                    {isGstTransactionPage && (
                      <CardActions align="left" sx={cellStyle}>
                        <Checkbox
                          checked={selectedInvoices.includes(row.id)}
                          onChange={() => handleCheckboxChange(row.id)}
                        />
                        <label htmlFor="">paid</label>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} textAlign="center">
                <Typography variant="body1" color="red">
                  No data found.
                </Typography>
              </Grid>
            )}
            {!loading && invoiceList.length > 0 && (
              <Box mt={2}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Box sx={{ overflowX: "auto", flex: 1 }}>
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{ py: 1, minWidth: 800 }}
            >
              <Table stickyHeader ref={gstInvoiceReportRef}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCellStyle}>S.No.</TableCell>
                    {headers.map((column) => (
                      <TableCell
                        key={column.id}
                        sortDirection={orderBy === column.id ? order : false}
                        sx={headerCellStyle}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={() => handleRequestSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    {/* {
                                (isDownload === false && isPrint === false) &&
                                <TableCell sx={headerCellStyle}>Action</TableCell>
                            } */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loading === false ? (
                    invoiceList.length > 0 ? (
                      invoiceList.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          sx={{
                            ...rowStyle,
                            ...(rowIndex % 2 === 0
                              ? evenRowStyle
                              : oddRowStyle),
                          }}
                        >
                          <TableCell sx={cellStyle}>
                            <Stack direction={"row"} spacing={1}>
                              <Typography variant="body1">
                                {page * rowsPerPage + rowIndex + 1}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell sx={cellStyle}>{row.gstin}</TableCell>
                          <TableCell sx={cellStyle}>{row.fname}</TableCell>
                          <TableCell sx={cellStyle}>
                            {row.invoice_number}
                          </TableCell>
                          <TableCell sx={cellStyle}>{row.date}</TableCell>
                          <TableCell sx={cellStyle}>
                            ₹{row.total_amount}
                          </TableCell>
                          <TableCell sx={cellStyle}>
                            ₹{row.taxable_amt}
                          </TableCell>
                          <TableCell sx={cellStyle}>{row.cgst}</TableCell>
                          <TableCell sx={cellStyle}>{row.sgst}</TableCell>
                          <TableCell sx={cellStyle}>{row.igst}</TableCell>
                          <TableCell sx={cellStyle}>
                            {row.cgst + row.sgst + row.igst}
                          </TableCell>

                          {/* {
                                            (isDownload === false && isPrint === false) &&
                                            <TableCell sx={cellStyle}>
                                                <Stack direction={'row'}>
                                                    <VisibilityIcon />
                                                </Stack>
                                            </TableCell>
                                        } */}
                          {isGstTransactionPage && (
                            <TableCell align="left" sx={cellStyle}>
                              <Checkbox
                                checked={selectedInvoices.includes(row.id)}
                                onChange={() => handleCheckboxChange(row.id)}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box textAlign={"center"}>
                            <Typography variant="body1" color={"red"}>
                              No data found.
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                        <div>
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 1 * emptyRows }}>
                      <TableCell colSpan={headers.length + 2} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ zIndex: 10000 }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Gst Transaction
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form>
            <div>
              <TextField
                size="small"
                required
                type="date"
                label="Required"
                InputLabelProps={{ shrink: true }}
                inputRef={dateRef}
              />
            </div>
            <div style={{ marginTop: "16px" }}>
              <TextField
                id="outlined-basic"
                label="Remarks"
                variant="outlined"
                defaultValue=""
                inputRef={remarksRef}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveChanges}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default GstInvoiceReport;
