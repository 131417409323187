import React from "react";
import {
    DialogContent,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    CardContent,
    Typography,
  } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useDeviceDetect from "../../Detect/useDeviceDetect ";

const SalesOrderPopUp = ({ handleClose, selectedProductData }) => {
  // Define the headers for the table
  const headers = [
    "S.No.",
    "Item Name",
    "Item Code",
    "Quantity",
    "Rate",
    "Amount",
  ];
  const { isMobile } = useDeviceDetect();

  // Sample data for table rows (you can replace it with actual data)
  const rows = [
    {
      id: 1,
      itemName: "Item 1",
      itemCode: "ITM001",
      quantity: 2,
      rate: 100,
      amount: 200,
    },
    {
      id: 2,
      itemName: "Item 2",
      itemCode: "ITM002",
      quantity: 1,
      rate: 150,
      amount: 150,
    },
    {
      id: 3,
      itemName: "Item 3",
      itemCode: "ITM003",
      quantity: 5,
      rate: 50,
      amount: 250,
    },
  ];

  return (
    <>
      {isMobile ? (
        <>
          {/* Mobile View: Card layout */}
          <DialogContent dividers>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <h3>Sales Order Details</h3>
              {selectedProductData.map((product, index) => (
                <Card key={index} style={{ marginBottom: "16px" }}>
                  <CardContent>
                    <Typography variant="h6" color="textPrimary">
                      {index + 1}. {product.itemname}
                    </Typography>
                    <Typography color="textSecondary">
                      Item Code: {product.item_code}
                    </Typography>
                    <Typography color="textSecondary">
                      Quantity: {product.qty}
                    </Typography>
                    <Typography color="textSecondary">
                      Rate: ₹{product.rate}
                    </Typography>
                    <Typography color="textSecondary">
                      Amount: ₹{product.amount}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </div>
          </DialogContent>
        </>
      ) : (
        <>
          {" "}
          <DialogContent dividers>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Sales Order Details</h3>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            {/* Table to display the sales order details */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell
                        key={index}
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProductData.map((product, index) => (
                    <TableRow key={product.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{product.itemname}</TableCell>
                      <TableCell>{product.item_code}</TableCell>
                      <TableCell>{product.qty}</TableCell>
                      <TableCell>{product.rate}</TableCell>
                      <TableCell>{product.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </>
      )}
    </>
  );
};

export default SalesOrderPopUp;
