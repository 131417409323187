import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { SessionVerificationAdmin } from "../Components/SessionManagement/SessionManagementAdmin";
import Header from "../Components/header/Header/Header";
import Sidebar from "../Components/sidebar/SideBar";
import useDeviceDetect from "../Components/Detect/useDeviceDetect ";
import SideBarRedesign from "../Components/sidebar/SideBarRedesign";
import FloatingActionButton from "./FloatingActionButton/FloatingActionButton";
import { useDispatch } from "react-redux";
import { rSetShowFab } from "../redux/reducers/GlobalVariables";
import { Link, useLocation } from "react-router-dom";


const AdminLayout = ({ children }) => {
  const { isMobile } = useDeviceDetect();
  const dispatch = useDispatch();
  const location = useLocation(); // Get current location
  //#region code
  const open = useSelector((state) => state.sideExpand.open);
  const { showFab, isLogin } = useSelector((state) => state.GlobalVariables);
  
  useEffect(() => {
    dispatch(rSetShowFab(true));
  }, [location]);

  console.log("showFab=", showFab, isLogin);

  //#endregion
  return (
    <>
      <SessionVerificationAdmin />

      {isMobile ? (
        <>
          {" "}
          <SideBarRedesign />
          {showFab && <FloatingActionButton />} {/* Conditionally render FAB */}
        </>
      ) : (
        <>
          {" "}
          <Header />
          <Sidebar />
        </>
      )}

      {isMobile ? (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
            }}
          >
            {/* Adjust the Main component to account for the sidebar */}
            <Box
              sx={{
                flexGrow: 1,
                marginLeft: open ? "0px" : "0px", // Adjust based on sidebar state
                transition: "margin 0.2s ease", // Smooth transition
                padding: "16px", // Add padding to content
                minHeight: "77vh",
              }}
            >
             
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  mt: 1,
                  mx: 0,
                  my: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  px: 0,
                  pt: 10,
                  pb: 0,
                }}
              >
                {children}
              </Paper>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              marginLeft: {
                xs: 7,
                sm: 8,
              },
              ...(open && { marginLeft: 33 }),
              transition: "200ms",
              minHeight: "77vh",
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  mx: 5,
                  my: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  px: 3,
                  pt: 0,
                  pb: 5,
                }}
              >
                {children}
              </Paper>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};

export default AdminLayout;
