import React, { memo, useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-hot-toast";

import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import configServ from "../../../services/config";
import AlertDialog from "../../AlertDialog/AlertDialog";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import useDeviceDetect from "../../Detect/useDeviceDetect ";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SalesOrderPopUp from "./SalesOrderPopUp";
import Swal from "sweetalert2";
import { rSetShowFab } from "../../../redux/reducers/GlobalVariables";

//FUNCTION
const SalesOrderTable = ({ isChanged, handleEditData, pagination = true }) => {
  const dispatch = useDispatch();
  const { company_id, is_super } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [count, setCount] = useState(0);
  const [searchBy, setSearchBy] = useState("partyname");
  const [isApproved, setIsApproved] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [orderDataList, setOrderDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [open, setOpen] = useState(false);
  const [openGstDialog, setOpenGstDialog] = useState(false);

  // isapproved part
  // Add a state to manage selected checkboxes
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const dateRef = useRef(null);
  const remarksRef = useRef(null);

  // Handle individual checkbox change
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle 'Select All' checkbox change
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]); // Unselect all
    } else {
      setSelectedRows(paginatedData.map((row) => row.id)); // Select all IDs
    }
    setSelectAll(!selectAll);
  };

  // Save selected data
  const handleSaveSelected = async () => {
    const dateValue = dateRef.current.value;

    // Check if the date is filled
    if (!dateValue) {
      toast.error("Please select a date before submitting.");
      return; // Stop the function execution if the date is not provided
    }

    const remarksValue = remarksRef.current.value || null;

    // Filter the selected rows and create the update object
    const selectedData = selectedRows
      .map((selectedId) => {
        const currentRow = paginatedData.find((row) => row.id === selectedId);

        if (currentRow) {
          return {
            id: currentRow.id, // Use the selected row's ID
            updated_at: dateValue, // Use the selected date
            remarks: remarksValue, // Use the remarks, or null if empty
          };
        }
        return null;
      })
      .filter((update) => update !== null); // Filter out any null entries

    if (selectedData.length > 0) {
      console.log("Saving the following selected data:", selectedData);

      try {
        setLoading(true);

        // Assuming you're using a service to send the data to the API
        const res = await configServ.approveSaleOrders(selectedData);
        if (res.status === 200) {
          toast.success("Selected data updated successfully!");
          // Refresh or fetch the updated data
          fetchSalesOrderList();
          handleClose(); // Close the dialog after successful submission
        } 
        else {
          toast.error("An error occurred while updating the data.");
          console.error("Failed to update selected data", res);
        }
      } 
      catch (error) {
        console.error("An error occurred while saving selected data", error);
        toast.error("An error occurred while saving the data.");
      } 
      finally {
        setOpenGstDialog(false);
        setLoading(false);
      }

      // Clear the selection after saving
      setSelectedRows([]); // Clear selected rows
      setSelectAll(false); // Reset select all state
    } else {
      toast.error("No rows selected.");
      console.log("No rows selected.");
    }
  };

  // Model Started
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const handleClickOpen = () => {
    dispatch(rSetShowFab(false));
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Dialog will open");
        setOpenGstDialog(true); // Updated to use new state
        // Keep showFab false if confirmed
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
        dispatch(rSetShowFab(true)); // Set showFab to true when 'Don't save' is clicked
      } else if (result.isDismissed) {
        // Handle the case where the dialog is dismissed (cancelled)
        dispatch(rSetShowFab(true));
      }
    });
  };

  const handleClose1 = () => {
    setOpenGstDialog(false); // Updated to use new state
  };
  // model end

  // isApproved end

  const [selectedProductData, setSelectedProductData] = useState([]);
  // handleVisibilityIcon started
  const handleVisibilityIcon = (row) => {
    // Assuming row.products contains the product details for that specific row
    const filteredProducts = row.items; // Filter the specific product
    console.log("filteredProducts=", filteredProducts);
    setSelectedProductData(filteredProducts); // Set the filtered product data
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false);
  };
  // handleVisibilityIcon end

  const headers = [
    "CUSTOMER NAME",
    "ORDER NO.",
    "ORDER DATE",
    "TOTAL AMOUNT",
    "STATUS",
    "Is Approved",
    "ACTION",
  ];
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    fetchSalesOrderList();
  }, [isChanged, isApproved]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? orderDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : orderDataList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const fetchSalesOrderList = async () => {
    try {
      setLoading(true);
      const res = await configServ.salesOrderList({
        company_id: company_id,
        isApproved: isApproved,
      });
      // const result = res.map((item, index) => ({
      //   id: item.id,
      //   customer_id: item.customer_id,
      //   partyname: item.partyname,
      //   billtoaddress: item.billtoaddress,
      //   shiptoaddress: item.shiptoaddress,
      //   order_no: item.order_no,
      //   order_date: CheckAndReturn.transformToDate2(item.order_date).toString() || 'N/A',
      //   order_type: item.order_type,
      //   po_number: item.po_number || 'N/A',
      //   po_date: CheckAndReturn.transformToDate2(item.po_date).toString() || 'N/A',
      //   validitydate: CheckAndReturn.transformToDate2(item.validitydate).toString() || 'N/A',
      //   total_amount: Number(item.total_amount),
      //   status: item.isDeleted,
      //   items: item.items,
      // }));
      setOrderDataList(res || []);
      setOrderList(res || []);
      setCount(res.length);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleIsApprovedChange = () => {
    setIsApproved(!isApproved);
  }
  

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === "") {
        data = orderList;
      } else if (searchBy === "partyname") {
        data = orderList.filter((x) =>
          x.partyname.toLowerCase().includes(search.toLowerCase())
        );
      }
      setOrderDataList(data);
      setCount(data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrint = (params) => {
    printOrder(params);
  };

  const printOrder = async (order_id) => {
    const data = {
      order_id: order_id,
    };
    dispatch(triggerLoader());
    try {
      const result = await configServ.printSalesOrder(data);
      const blobFile = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blobFile);
      window.open(url);
    } catch (err) {
      console.log(err);
      handleOpenDialog("Print failed");
    } finally {
      dispatch(triggerLoader());
    }
  };

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <AlertDialog
          open={dialogOpen}
          setOpen={handleCloseDialog}
          title="Alert"
          message={dialogContent}
          buttonTitle="Ok"
        />

        {/* Modal/Dialog for Product Details */}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>Product Details</DialogTitle>
          <SalesOrderPopUp
            handleClose={handleClose}
            selectedProductData={selectedProductData}
          />
        </Dialog>

        {/* search starts here */}
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          style={{ pt: 1 }}
          spacing={1}
          direction={"row"}
        >
          <Grid
            item
            xs={12}
            md={6}
            ml={0}
            display="flex"
            alignItems="center"
            justifyContent={"flex-start"}
            flexWrap="wrap" // Ensures items wrap on small devices
          >
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "18px" }, // Smaller font on mobile
                fontWeight: "bold",
                mr: 2,
              }}
            >
              Sales Order List : {count}
            </Typography>

            {/* Check All Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isApproved}
                  onChange={handleIsApprovedChange} // Toggle Select All
                />
              }
              label="Is Approved"
              sx={{
                mr: 2,
                fontSize: { xs: "14px", md: "16px" }, // Adjust font size for small devices
              }}
            />

            {/* Save Button */}
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleClickOpen}
              sx={{
                ml: { xs: 0, md: 2 }, // No margin-left on small devices, add on medium+
                mt: { xs: 2, md: 0 }, // Margin-top on small devices to stack below the checkbox
                width: { xs: "100%", md: "auto" }, // Full-width on small devices
              }}
            >
              Approve
            </Button>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  onChange={handleSearchBy}
                  inputProps={{
                    name: "searchBy",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value={"partyname"}>Customer</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            mr={{ xs: 0, md: 0 }}
            justifyContent={"flex-end"}
          >
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: "grey" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* search ends here */}

        {isMobile ? (
          <>
            {" "}
            <Grid container spacing={2}>
              {paginatedData.map((row, rowIndex) => (
                <Grid item xs={12} key={rowIndex}>
                  <Card sx={{ p: 2, mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {page * rowsPerPage + rowIndex + 1}. {row.partyname}
                      </Typography>
                      <Typography>
                        <strong>Order No: </strong> {row.order_no}
                      </Typography>
                      <Typography>
                        <strong>Order Date: </strong>
                        {CheckAndReturn.transformToDate2(
                          row.order_date
                        ).toString() || "N/A"}
                      </Typography>
                      <Typography>
                        <strong>Total Amount: </strong> ₹{row.total_amount}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Tooltip title="Print" placement="top">
                        <IconButton onClick={() => handlePrint(row.id)}>
                          <PrintTwoToneIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        <IconButton onClick={() => handleEditData(row)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="See Details" arrow>
                        <IconButton onClick={() => handleVisibilityIcon(row)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                    <CardActions align="left">
                      <Checkbox
                        checked={selectedRows.includes(row.id)}
                        onChange={() => handleCheckboxChange(row.id)}
                      />
                      <label htmlFor="">Approve</label>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ py: 1 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCellStyle}>S.No.</TableCell>
                    {headers.map((header, index) => (
                      <TableCell key={index} style={headerCellStyle}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                      <TableCell>{row.partyname}</TableCell>
                      <TableCell>{row.order_no}</TableCell>
                      <TableCell>
                        {CheckAndReturn.transformToDate2(
                          row.order_date
                        ).toString() || "N/A"}
                      </TableCell>
                      <TableCell>₹{row.total_amount}</TableCell>
                      <TableCell>{row.order_status}</TableCell>
                      <TableCell>
                        <Checkbox
                          disabled={isApproved || Boolean(!is_super)}
                          checked={
                            isApproved === false?
                            selectedRows.includes(row.id): true
                          }
                          onChange={() => handleCheckboxChange(row.id)}
                        />
                      </TableCell>

                      {/* <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell> */}
                      <TableCell>
                        <Box
                          sx={{
                            borderRadius: 10,
                            width: "fit-content",
                            transition: "100ms",
                            cursor: "pointer",
                            padding: 0.5,
                          }}
                        >
                          <Tooltip title="Print" placement="top">
                            <IconButton onClick={() => handlePrint(row.id)}>
                              <PrintTwoToneIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit" arrow>
                            <IconButton onClick={() => handleEditData(row)}>
                              <EditIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="See Details" arrow>
                            <IconButton
                              onClick={() => handleVisibilityIcon(row)}
                            >
                              <VisibilityIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 1 * emptyRows }}>
                      <TableCell colSpan={headers.length + 2} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              {pagination && (
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TableContainer>
          </>
        )}

        <BootstrapDialog
          onClose={handleClose1}
          aria-labelledby="customized-dialog-title"
          open={openGstDialog}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Sales Order
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose1}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <form>
              <div>
                <TextField
                  size="small"
                  required
                  type="date"
                  label="Required"
                  InputLabelProps={{ shrink: true }}
                  inputRef={dateRef}
                />
              </div>
              <div style={{ marginTop: "16px" }}>
                <TextField
                  id="outlined-basic"
                  label="Remarks"
                  variant="outlined"
                  defaultValue=""
                  inputRef={remarksRef}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleSaveSelected}>
              Save changes
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </>
    );
  }
};

export default memo(SalesOrderTable);
