import React, { useEffect, useState } from 'react'
import configServ from '../../services/config';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Grid,
} from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { useSelector } from 'react-redux';
import RolePopup from "../../Components/Card/CreateRoles/RolePopup"
import CustomUser from '../../Components/customUser/CustomUser';

//FUNCTION
const AccessMasterCard = ({ open }) => {
    //#region code
    // const app = useSelector((state) => state.GlobalVariables.noRoomService);
    const { user_id, company_id, is_super, permissions } = useSelector((state) => state.GlobalVariables);
    const [role, setRole] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [openCreateRole, setOpenCreateRole] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [isSupplier, setIsSupplier] = useState(false);
    const [isManufacturer, setIsManufacturer] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        if (role.toLowerCase().includes('supplier')) {
            setIsCustomer(false);
            setIsManufacturer(false);
            setIsSupplier(true);
        }
        else if (role.toLowerCase().includes('manufacturer')) {
            setIsCustomer(false);
            setIsManufacturer(true);
            setIsSupplier(false);
        }
        else if (role === '') {
            setIsCustomer(false);
            setIsManufacturer(false);
            setIsSupplier(false);
        }
        else {
            setIsCustomer(true);
            setIsManufacturer(false);
            setIsSupplier(false);
        }
    }, [role]);

    const handleCreateRoleOpen = () => {
        setOpenCreateRole(true);
    };

    const handleCreateRoleClose = () => {
        fetchRoles();
        setOpenCreateRole(false);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent("");
    };

    const fetchRoles = async () => {
        try {
            const result = await configServ.getRoles({ "user_id": user_id, "is_super": is_super })
            if (result.status === 200) {
                setRoleList(result.data);
            }
            else {
                setRoleList([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />

            <RolePopup
                open={openCreateRole}
                handleClose={handleCreateRoleClose}
            />

            {open && (
                <>
                    <Grid container item xs={12} md={12} justifyContent={'flex-end'}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={handleCreateRoleOpen}
                            startIcon={<PersonAddIcon />}
                            sx={{ mt: 1 }}
                        >
                            Create Roles
                        </Button>
                    </Grid>

                    <CustomUser role={role} />


                </>
            )}

            {/* <AssignRolesPage /> */}
        </>
    );
};

export default AccessMasterCard;
