import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { topUsers } from "./topUser"; // Assuming topUsers is a list of users
import configServ from "../../services/config";
import { useSelector } from "react-redux";

// Function to generate random password
const generateRandomPassword = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let password = "";
  for (let i = 0; i < 8; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
};
const CustomerPassword = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [customers, setCustomers] = useState([]);
  const { company_id } = useSelector((state) => state.GlobalVariables);

  const handleGeneratePassword = () => {
    const newPassword = generateRandomPassword();
    setGeneratedPassword(newPassword);
  };

  const fetchCustomerList = async () => {
    try {
      const result = await configServ.get_customer({
        company_id: company_id,
      });
      // console.log(
      //   "fetchCustomerList=",
      //   result.map((item) => item.fname)
      // );

      // Log each customer's first name
      // result.forEach((customer) => console.log(customer.fname));

      setCustomers(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);
  const handleSave = () => {
    if (selectedUser) {
      // const company_id = company_id; // Assuming company_id is already defined elsewhere in your code
      const Customer_ID = selectedUser.id;
      const Customer_Name = selectedUser.fname;
      const Generated_Password = generatedPassword;
  
      // Log the details
      console.log({
        company_id,
        Customer_ID,
        Customer_Name,
        Generated_Password
      });
  
      // Here you can send these details to the endpoint as required
    } else {
      console.log("No user selected.");
    }
  };
  

  return (
    <>
      {/* Autocomplete to select user */}
      <Autocomplete
        disablePortal
        options={customers} // Use the customers fetched from the API
        getOptionLabel={(option) => option.fname || ""} // Display the fname of each customer
        sx={{ width: 300, marginBottom: 2, mt: 2 }}
        renderInput={(params) => <TextField {...params} label="Select User" />}
        onChange={(event, value) => setSelectedUser(value)} // Set the selected customer
      />

      {/* TextField to display the generated password */}
      <TextField
        label="Generated Password"
        value={generatedPassword}
        InputProps={{ readOnly: true }}
        sx={{ width: 300, marginBottom: 2 }}
      />

      {/* Button to generate password */}
      <Button
        variant="contained"
        onClick={handleGeneratePassword}
        disabled={!selectedUser} // Disable if no user is selected
      >
        Generate Password
      </Button>
      {/* save to database */}
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        onClick={handleSave}
        disabled={!selectedUser}
      >
        Save
      </Button>
    </>
  );
};

export default CustomerPassword;
