import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";

// Styled Button component with hover effect
const HoverButton = styled(Button)({
  ":hover": {
    backgroundColor: "lightgray", // Change to desired hover color
    color: "black", // Change to desired text color on hover
  },
});

export default function YesNoDialog({
  open,
  setOpen,
  title,
  message,
  buttonTitleYes,
  buttonTitleNo,
  handleYes,
}) {
  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleYesClick = () => {
    setBackdropOpen(true); // Show backdrop when "Yes" is clicked
    handleYes(); // Call the original handleYes function
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 10000 }} // Set the dialog's z-index to 9000
      >
        <DialogTitle id="alert-dialog-title">{title || "Title"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || "Message"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <HoverButton onClick={handleClose} autoFocus>
            {buttonTitleNo || "No"}
          </HoverButton>
          <HoverButton onClick={handleYesClick}>
            {buttonTitleYes || "Yes"}
          </HoverButton>
        </DialogActions>
      </Dialog>

      {/* Backdrop with CircularProgress */}
      <Backdrop
        sx={{ color: "#fff", zIndex: 11000 }}
        open={backdropOpen}
        onClick={null} // Disable click action to keep loader visible
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
