import { Button, Checkbox, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import configServ from '../../../services/config';
import AlertDialog from '../../AlertDialog/AlertDialog';

const CreatePermission = () => {
    //#region code
    const initialFormData = {
        name: '',
        status: false
    }
    const [formData, setFormData] = useState(initialFormData);
    const [validationErrors, setValidationErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleChange = (params) => {
        try {
            const { name, value, type, checked } = params.target;

            setFormData((prev) => ({
                ...prev,
                [name]: type === 'checkbox' ? checked : value,
            }));

            if (validationErrors[name]) {
                setValidationErrors((prev) => ({
                    ...prev,
                    [name]: ''
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validateForm = async() => {
        try {
            const errors = {};
            if (!formData.name) {
                errors.name = "required";
            }
            setValidationErrors(errors);
            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const isValid = await validateForm();
            if (isValid === false) {
                return false;
            }
            const res = await configServ.createPermissions(formData);
            if(res.status === 200){
                handleOpenDialog(res.message);
            }
            else{
                setDialogOpen(true);
                handleOpenDialog('Failed to create permissions.');
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleReset = () => {
        setFormData(initialFormData);
        setValidationErrors({});
    }
    //#endregion

    return (
        <>
        <AlertDialog open={dialogOpen} setOpen={handleCloseDialog} title="Alert" message={dialogContent} buttonTitle="Ok" />
        <form onSubmit={handleSubmit} onReset={handleReset}>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        size='small'
                        label='Permission'
                        name='name'
                        value={formData.name || ''}
                        onChange={handleChange}
                        sx={{
                            background: 'white'
                        }}
                        error={!!validationErrors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                        name='status'
                        control={<Checkbox checked={Boolean(formData.status) || false} />}
                        onChange={handleChange}
                        label="Status"
                        sx={{ mr: '20px' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack direction={'row'} spacing={2}>
                        <Button
                            type='reset'
                            variant="contained"
                            color='secondary'
                            startIcon={<RotateLeftIcon />}
                        >
                            Reset
                        </Button>
                        <Button
                            type='submit'
                            autoFocus
                            variant="contained"
                            color={isEdit ? 'success' : 'primary'}
                            startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                        >
                            {isEdit ? "Update" : "Save"}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            </form>
        </>
    )
}

export default CreatePermission;
