import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Stack, Menu, MenuItem, Avatar, Button } from "@mui/material";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import MailIcon from "@mui/icons-material/Mail";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SiteLogo from "../Assets/images/siteLogoWhite.svg";
import { Link, useNavigate } from "react-router-dom";

import InvoiceIcon from "../Assets/images/InvoiceIcon.png";
import BrandIcon from "../Assets/images/BrandIcon.png";
import CategoryIcon from "@mui/icons-material/Category";
import AccountMasterIcon2 from "../Assets/images/accountMasterEntry.png";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountMasterIcon from "../Assets/images/AccountMasterIcon.png";
import SupplierIcon from "../Assets/images/SupplierIcon.webp";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import stockUpdate from "../Assets/images/stockUpdate.png";
import Person4Icon from "@mui/icons-material/Person4";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReportIcon from "@mui/icons-material/Report";
import SellIcon from "@mui/icons-material/Sell";
import SavingsIcon from "@mui/icons-material/Savings";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SaleOrderIcon from "../Assets/images/SaleOrderIcon.png";
import ScaleIcon from "@mui/icons-material/Scale";
import HSNIcon from "../Assets/images/HSNIcon.png";
import SuperCategoryIcon from "../Assets/images/SuperCategoryIcon.jpg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CatalogIcon from "../Assets/images/CatalogIcon.jpg";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";


import { useEffect, useRef, useState } from "react";

import {
  rSetCompanyAddress,
  rSetCompanyCountry,
  rSetCompanyId,
  rSetCompanyName,
  rSetCompanyState,
} from "../../redux/reducers/GlobalVariables";
import { useDispatch, useSelector } from "react-redux";
import configServ from "../../services/config";
import { assignType } from "../../redux/reducers/Admin";
import SiteLogoInitial from "../Assets/images/SiteIcon.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LogoutIcon from "@mui/icons-material/Logout";
import Logout from "../Logout/Logout";
import YesNoDialog from "../AlertDialog/YesNoDialog";
import LoyaltyIcon from "@mui/icons-material/Loyalty";



const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#555", // Match sidebar background
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBarRedesign = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [expandedSection, setExpandedSection] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState(null);
  const [activeSubItem, setActiveSubItem] = React.useState(null); // Track active subcategory
  const menuRef = useRef(null);

  // header wala copy past
  const { user_id, company_id, companyName, is_super } = useSelector(
    (state) => state.GlobalVariables
  );
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const navigate = useNavigate();
  const [companySwitchPopup, setCompanySwitchPopup] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);

  const handleMenuItemClick = (item) => {
    setActiveItem(item); // Set the active item when clicked
  };
  const handleSubItemClick = (subItem) => {
    setActiveSubItem(subItem); // Set the active subcategory when clicked
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSectionClick = (section) => {
    setExpandedSection((prevSection) =>
      prevSection === section ? null : section
    );
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: "white", // Icon color
    fontSize: "2rem", // Increase icon size
    fontWeight: "bold", // Make icon bold
  }));

  const handleMenuItemClickAndClose = (item) => {
    handleMenuItemClick(item);
    setExpandedSection(null); // Close all expanded sections
    setOpen(false); // Close the sidebar
  };
  const handleSubItemClickAndClose = (subItem) => {
    handleSubItemClick(subItem);
    setOpen(false); // Close the sidebar
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // header wala function sab
  const assignCompanyLogo = async () => {
    try {
      const result = await configServ.company_logo({ company_id: company_id });
      if (result.image) {
        setCompanyLogo(`data:image/png;base64,${result.image}`);
      } else {
        setCompanyLogo("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchCompanyList = async () => {
    try {
      const result = await configServ.getCompanyList({ user_id: user_id });
      setCompanyList(result);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchCompanyList();
    assignCompanyLogo();
  }, []);
  const switchCompany = async () => {
    dispatch(rSetCompanyId(companyData.id));
    const data = {
      id: companyData.id,
    };

    const res = await configServ.getCompanyById(data);
    if (res.status === 200) {
      dispatch(rSetCompanyName(res.data.CompanyName));
      dispatch(rSetCompanyAddress(res.data.Address));
      dispatch(rSetCompanyCountry(res.data.country));
      dispatch(rSetCompanyState(res.data.State));
    }
    //localStorage.setItem('company_type', companyData.CompanyType);
    dispatch(assignType(companyData.CompanyType));
    const dataToSend = {
      user_id: user_id,
      company_id: companyData.id,
    };
    try {
      const result = await configServ.setActiveCompany(dataToSend);
      console.log(result);
    } catch (err) {
      console.log(err);
    }
    navigate(0);
  };
  const handleCompanySwitchPopup = () => {
    setCompanySwitchPopup(!companySwitchPopup);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  function stringAvatar(name) {
    const initials = name
      ? name.includes(" ")
        ? `${name.split(" ")[0][0].toUpperCase()}${name
            .split(" ")[1][0]
            .toUpperCase()}`
        : `${name[0].toUpperCase()}`
      : "W";

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  }
  function stringToColor(string) {
    // Return a default color if the input string is undefined or null
    if (!string) {
      return "#000000"; // You can choose any default color you prefer
    }

    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  const handleClose2 = () => {
    setAnchorEl2(null);
    //   console.log(anchorEl2)
  };
  const handleLogout = () => {
    setIsLogutDialogOpen(!isLogutDialogOpen);
  };
  const [isLogutDialogOpen, setIsLogutDialogOpen] = useState(false);

  return (
    <>
      <Logout open={isLogutDialogOpen} setOpen={setIsLogutDialogOpen} />
      <YesNoDialog
        open={companySwitchPopup}
        setOpen={setCompanySwitchPopup}
        title={"Company Switch"}
        message={"Do you want to switch company?"}
        handleYes={switchCompany}
      />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CustomAppBar
          position="fixed"
          open={open}
          sx={{ backgroundColor: "white", color: "black" }} // White header with black text
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon sx={{ color: "black" }} /> {/* Black icon */}
            </IconButton>
            {/* Replacing the Typography with the company logo/name logic */}
            <Stack maxWidth={1000} height="100%" justifyContent="center">
              <IconButton
                maxWidth={1000}
                id="basic-button"
                aria-controls={open1 ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={is_super === 1 ? handleClick : () => {}}
              >
                {companyLogo === "" ? (
                  <Typography
                    variant="button"
                    sx={{
                      p: 1,
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: 700,
                      color: "black",
                      ":hover": {
                        color: "grey",
                      },
                    }}
                  >
                    {companyName}
                  </Typography>
                ) : (
                  <Box
                    component="img"
                    src={companyLogo || SiteLogoInitial}
                    alt="logoInitial"
                    height="50"
                    title={companyName}
                    sx={{
                      borderRadius: "50%", // This makes the image circular
                      width: "50px", // Ensure width matches height for a perfect circle
                      objectFit: "cover", // Ensures the image scales correctly inside the circle
                    }}
                  />
                )}
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {companyList
                  .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName))
                  .map((item) => (
                    <MenuItem
                      key={item.id}
                      sx={{
                        padding: { xs: 1, md: 2 },
                        height: { xs: "2.5rem", md: "3.5rem" },
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        handleClose();
                        setCompanyData(item);
                        handleCompanySwitchPopup();
                      }}
                    >
                      {item.CompanyLogo === null || item.CompanyLogo === "" ? (
                        <span style={{ color: "#1e61b4" }}>
                          {item.CompanyName}
                        </span>
                      ) : (
                        <>
                          <img
                            style={{ padding: "5px" }}
                            src={`data:image/png;base64,${item.CompanyLogo}`}
                            alt={item.CompanyName}
                            width="50"
                          />
                          <span style={{ color: "#1e61b4" }}>
                            {item.CompanyName}
                          </span>
                        </>
                      )}
                    </MenuItem>
                  ))}
              </Menu>
            </Stack>

            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              id="basic-button2"
              aria-controls={open2 ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              onClick={handleClick2}
              sx={{
                width: "5%",
                px: 7,
                //backgroundColor: 'rgba(0, 0, 0, 0.08)',
                borderRadius: "20px",
                cursor: "pointer",
                ":hover": {
                  //backgroundColor: 'rgba(0, 0, 0, 0.16)',
                },
              }}
            >
              <Avatar {...stringAvatar(companyName)} />
              {anchorEl2 === null ? (
                <ArrowDropDownIcon sx={{ marginRight: 1, fontSize: "30px" }} />
              ) : (
                <ArrowDropUpIcon
                  sx={{ marginRight: 0, fontSize: "30px" }}
                  color="primary"
                />
              )}
              {/* <SettingsIcon sx={{marginLeft:1}}/> */}
            </IconButton>
            <Menu
              id="basic-menu2"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              MenuListProps={{
                "aria-labelledby": "basic-button2",
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/profile">
                <MenuItem onClick={handleClose2} sx={{ padding: 0 }}>
                  <Button
                    sx={{ color: "black", padding: 2 }}
                    startIcon={<AccountBoxIcon />}
                  >
                    My Profile
                  </Button>
                </MenuItem>
              </Link>
              <MenuItem
                onClick={() => {
                  handleClose2();
                  handleLogout();
                }}
                sx={{ padding: 0 }}
              >
                <Button
                  sx={{ color: "black", padding: 2 }}
                  startIcon={<LogoutIcon />}
                >
                  Log Out
                </Button>
              </MenuItem>
            </Menu>
          </Toolbar>
        </CustomAppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#555", // Sidebar background
              color: "white", // Text color
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
          ref={menuRef}
        >
          <DrawerHeader>
          <img
            src={SiteLogo}
            alt="logo"
            width="150"
            hidden={open ? false : true}
          />
            <StyledIconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon sx={{ color: "white" }} />
              ) : (
                <ChevronRightIcon sx={{ color: "white" }} />
              )}
            </StyledIconButton>
          </DrawerHeader>
          <Divider />

          <List sx={{ mb: 4 }}>
            {/* Dashboard */}
            <ListItemButton
              component={Link} // Use Link as the component
              to="/dashboard"
              onClick={() => handleMenuItemClickAndClose("dashboard")}
              sx={{
                backgroundColor:
                  activeItem === "dashboard" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "dashboard" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <DashboardIcon
                  style={{
                    color: activeItem === "dashboard" ? "#fff" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>

            <Divider />
            {/* Inventory Section */}
            <ListItemButton
              onClick={() => {
                handleMenuItemClick("inventory");
                handleSectionClick("inventory");
              }}
              sx={{
                backgroundColor:
                  activeItem === "inventory" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "inventory" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <InventoryIcon
                  style={{
                    color: activeItem === "inventory" ? "#fff" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Inventory Master" />
              {expandedSection === "inventory" ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            <Collapse
              in={expandedSection === "inventory"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {/* Subcategory 1 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/brand"
                  onClick={() => handleSubItemClickAndClose("Brand")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Brand" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Brand" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={BrandIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                        color: activeSubItem === "Brand" ? "#fff" : "white",
                      }}
                      alt="Brand"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Brand " />
                </ListItemButton>
                {/* sub2 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/category"
                  onClick={() => handleSubItemClickAndClose("Category")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Category" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Category" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <CategoryIcon
                      style={{
                        color: activeSubItem === "Category" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Category" />
                </ListItemButton>
                {/* sub3 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/group"
                  onClick={() => handleSubItemClickAndClose("Group")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Group" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Group" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <SellIcon
                      style={{
                        color: activeSubItem === "Group" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Group" />
                </ListItemButton>
                {/* sub4 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/units"
                  onClick={() => handleSubItemClickAndClose("Unit")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Unit" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Unit" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <ScaleIcon
                      style={{
                        color: activeSubItem === "Unit" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Unit" />
                </ListItemButton>
                {/* sub5 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/item"
                  onClick={() => handleSubItemClickAndClose("Items")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Items" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Items" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <ViewInArIcon
                      style={{
                        color: activeSubItem === "Items" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Items" />
                </ListItemButton>
                {/* sub6 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/hsn"
                  onClick={() => handleSubItemClickAndClose("HSN")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "HSN" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "HSN" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={HSNIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                        color: activeSubItem === "HSN" ? "#fff" : "white",
                      }}
                      alt="HSN"
                    />
                  </ListItemIcon>
                  <ListItemText primary="HSN" />
                </ListItemButton>
                {/* sub7 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/super-category"
                  onClick={() => handleSubItemClickAndClose("Super Category")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Super Category" ? "#444" : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "Super Category" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={SuperCategoryIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                        color:
                          activeSubItem === "Super Category" ? "#fff" : "white",
                      }}
                      alt="Super Category"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Super Category" />
                </ListItemButton>
                {/* sub8 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/location"
                  onClick={() => handleSubItemClickAndClose("Location")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Location" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Location" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <LocationOnIcon
                      style={{
                        color: activeSubItem === "Location" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Location" />
                </ListItemButton>
                {/* sub9 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/master-entry/catalog"
                  onClick={() => handleSubItemClickAndClose("Catalog")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Catalog" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Catalog" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={CatalogIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                        color: activeSubItem === "Catalog" ? "#fff" : "white",
                      }}
                      alt="Catalog"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Catalog" />
                </ListItemButton>
                {/* You can add more subcategories similarly */}
              </List>
            </Collapse>

            {/* Invoice */}
            <ListItemButton
              component={Link} // Use Link as the component
              to="/invoice"
              onClick={() => handleMenuItemClickAndClose("invoice")}
              sx={{
                backgroundColor: activeItem === "invoice" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "invoice" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <img
                  src={InvoiceIcon}
                  style={{
                    width: "25px",
                    filter: "invert(100%)",
                    color: activeItem === "invoice" ? "#fff" : "white",
                  }}
                  alt="Invoice"
                />
              </ListItemIcon>
              <ListItemText primary="Invoice" />
            </ListItemButton>
            {/* Subscriptions */}
            <ListItemButton
              component={Link} // Use Link as the component
              to="/subscriptions"
              onClick={() => handleMenuItemClickAndClose("Subscriptions")}
              sx={{
                backgroundColor:
                  activeItem === "Subscriptions" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Subscriptions" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <AssessmentIcon
                  sx={{
                    color: activeItem === "Subscriptions" ? "#fff" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Subscriptions" />
            </ListItemButton>
            
            {/* subscriptions-order */}
            {/* <ListItemButton
              component={Link} // Use Link as the component
              to="/subscriptions-order"
              onClick={() => handleMenuItemClickAndClose("Subscriptions Order")}
              sx={{
                backgroundColor:
                  activeItem === "Subscriptions Order" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Subscriptions Order" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <SubscriptionsIcon
                  sx={{
                    color: activeItem === "Subscriptions Order" ? "#fff" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Subscriptions Order" />
            </ListItemButton>
             <ListItemButton
              component={Link} // Use Link as the component
              to="/subscriptions-dispatch"
              onClick={() => handleMenuItemClickAndClose("Subscriptions Dispatch")}
              sx={{
                backgroundColor:
                  activeItem === "Subscriptions Dispatch" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Subscriptions Dispatch" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <LoyaltyIcon
                  sx={{
                    color: activeItem === "Subscriptions Dispatch" ? "#fff" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Subscriptions Dispatch" />
            </ListItemButton> */}

            {/* Sales Order */}
            <ListItemButton
              component={Link} // Use Link as the component
              to="/sales-order"
              onClick={() => handleMenuItemClickAndClose("Sales Order")}
              sx={{
                backgroundColor:
                  activeItem === "Sales Order" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Sales Order" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <img
                  src={SaleOrderIcon}
                  style={{
                    width: "25px",
                    filter: "invert(100%)",
                    color: activeItem === "Sales Order" ? "#fff" : "white",
                  }}
                  alt="Sales Order"
                />
              </ListItemIcon>
              <ListItemText primary="Sales Order" />
            </ListItemButton>

            {/* Account Master entry stated  */}
            <ListItemButton
              onClick={() => {
                handleMenuItemClick(" AccountMasterEntry");
                handleSectionClick("AccountMasterEntry");
              }}
              sx={{
                backgroundColor:
                  activeItem === "AccountMasterEntry" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "AccountMasterEntry" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <img
                  src={AccountMasterIcon2}
                  style={{
                    width: "25px",
                    filter: "invert(100%)",
                    color:
                      activeItem === "invAccountMasterEntryentory"
                        ? "#fff"
                        : "white",
                  }}
                  alt="Account Master"
                />
              </ListItemIcon>
              <ListItemText primary=" Account Master" />
              {expandedSection === "AccountMasterEntry" ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
            <Collapse
              in={expandedSection === "AccountMasterEntry"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {/* Subcategory 1 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/account-master-entry/customers"
                  onClick={() => handleSubItemClickAndClose("Customers")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Customers" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Customers" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <PeopleAltIcon
                      sx={{
                        color: activeSubItem === "Customers" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Customers " />
                </ListItemButton>

                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/account-master-entry/account-master"
                  onClick={() => handleSubItemClickAndClose("AccountMaster")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "AccountMaster" ? "#444" : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "AccountMaster" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={AccountMasterIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                        color:
                          activeSubItem === "AccountMaster" ? "#fff" : "white",
                      }}
                      alt="Account Master"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Account Master" />
                </ListItemButton>

                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/account-master-entry/supplier"
                  onClick={() => handleSubItemClickAndClose("Suppliers")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Suppliers" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Suppliers" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={SupplierIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                        color: activeSubItem === "Suppliers" ? "#fff" : "white",
                      }}
                      alt="Suppliers"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Suppliers" />
                </ListItemButton>
                {/* You can add more subcategories similarly */}
              </List>
            </Collapse>
            {/* TRANSACTION STARTED */}
            <ListItemButton
              onClick={() => {
                handleMenuItemClick("Transaction");
                handleSectionClick("Transaction");
              }}
              sx={{
                backgroundColor:
                  activeItem === "Transaction" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Transaction" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <AccountBalanceIcon
                  sx={{
                    color: activeItem === "Transaction" ? "#fff" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary=" Transaction" />
              {expandedSection === "Transaction" ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
            {/* Transaction Sub List */}
            <Collapse
              in={expandedSection === "Transaction"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {/* Subcategory 1 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/transaction/create-vouchers"
                  onClick={() => handleSubItemClickAndClose("Cash Voucher")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Cash Voucher" ? "#444" : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "Cash Voucher" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <MonetizationOnIcon
                      sx={{
                        color:
                          activeSubItem === "Cash Voucher" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Cash Voucher " />
                </ListItemButton>
                {/* sub2 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/transaction/journal-entry"
                  onClick={() => handleSubItemClickAndClose("Journal Voucher")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Journal Voucher" ? "#444" : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "Journal Voucher" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <PriceChangeIcon
                      sx={{
                        color:
                          activeSubItem === "Journal Voucher"
                            ? "#fff"
                            : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Journal Voucher" />
                </ListItemButton>
                {/* sub3 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/transaction/Gst-transaction-report"
                  onClick={() => handleSubItemClickAndClose("Gst Transaction")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Gst Transaction" ? "#444" : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "Gst Transaction" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <ReceiptLongIcon
                      sx={{
                        color: activeSubItem === "Suppliers" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Gst Transaction" />
                </ListItemButton>
                {/* sub4 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/transaction/stockUpdate"
                  onClick={() => handleSubItemClickAndClose("stock Update")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "stock Update" ? "#444" : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "stock Update" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={stockUpdate}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                        color:
                          activeSubItem === "stock Update" ? "#fff" : "white",
                      }}
                      alt="stock Update"
                    />
                  </ListItemIcon>
                  <ListItemText primary="stock Update" />
                </ListItemButton>
                {/* sub5 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/transaction/purchase-order"
                  onClick={() => handleSubItemClickAndClose("Purchase Order")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Purchase Order" ? "#444" : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "Purchase Order" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <Person4Icon
                      sx={{
                        color:
                          activeSubItem === "Purchase Order" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Purchase Order" />
                </ListItemButton>
                {/* sub6 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/transaction/purchase-bill"
                  onClick={() => handleSubItemClickAndClose("Purchase Bill")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Purchase Bill" ? "#444" : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "Purchase Bill" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <ViewInArIcon
                      sx={{
                        color:
                          activeSubItem === "Purchase Bill" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Purchase Bill" />
                </ListItemButton>
                {/* sub7 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/transaction/dispatch"
                  onClick={() => handleSubItemClickAndClose("Dispatch")}
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Dispatch" ? "#444" : "inherit", // Highlight subcategory if active
                    color: activeSubItem === "Dispatch" ? "#fff" : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <LocalShippingIcon
                      sx={{
                        color: activeSubItem === "Dispatch" ? "#fff" : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Dispatch" />
                </ListItemButton>
                {/* You can add more subcategories similarly */}
              </List>
            </Collapse>

            {/* Reports Started */}
            <ListItemButton
              onClick={() => {
                handleMenuItemClick("Reports");
                handleSectionClick("Reports");
              }}
              sx={{
                backgroundColor: activeItem === "Reports" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Reports" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <ReportIcon
                  sx={{ color: activeItem === "Reports" ? "#fff" : "white" }}
                />
              </ListItemIcon>
              <ListItemText primary=" Reports" />
              {expandedSection === "Reports" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {/* Reports SubCategories */}
            <Collapse
              in={expandedSection === "Reports"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {/* Subcategory 1 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/report/outstanding-report"
                  onClick={() =>
                    handleSubItemClickAndClose("Outstanding Report")
                  }
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Outstanding Report"
                        ? "#444"
                        : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "Outstanding Report"
                        ? "#fff"
                        : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <SellIcon
                      sx={{
                        color:
                          activeSubItem === "Outstanding Report"
                            ? "#fff"
                            : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Outstanding Report " />
                </ListItemButton>
                {/* Subcategory 2 */}
                <ListItemButton
                  component={Link} // Use Link as the component
                  to="/report/gst-invoice-report"
                  onClick={() =>
                    handleSubItemClickAndClose("Gst Invoice Report")
                  }
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeSubItem === "Gst Invoice Report"
                        ? "#444"
                        : "inherit", // Highlight subcategory if active
                    color:
                      activeSubItem === "Gst Invoice Report"
                        ? "#fff"
                        : "inherit", // White text if active
                  }}
                >
                  <ListItemIcon>
                    <PriceChangeIcon
                      sx={{
                        color:
                          activeSubItem === "Gst Invoice Report"
                            ? "#fff"
                            : "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Gst Invoice Report" />
                </ListItemButton>
                {/* You can add more subcategories similarly */}
              </List>
            </Collapse>
            {/* Unregistered Payments */}

            <ListItemButton
              component={Link} // Use Link as the component
              to="/unregister-payment"
              onClick={() => handleMenuItemClickAndClose("Unregister Payments")}
              sx={{
                backgroundColor:
                  activeItem === "Unregister Payments" ? "#333" : "inherit", // Highlight if active
                color:
                  activeItem === "Unregister Payments" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <SavingsIcon
                  style={{
                    color:
                      activeItem === "Unregister Payments" ? "#fff" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Unregister Payments" />
            </ListItemButton>
            {/* company */}
            <ListItemButton
              component={Link} // Use Link as the component
              to="/register-company"
              onClick={() => handleMenuItemClickAndClose("Company")}
              sx={{
                backgroundColor: activeItem === "Company" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Company" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <AppRegistrationIcon
                  style={{ color: activeItem === "Company" ? "#fff" : "white" }}
                />
              </ListItemIcon>
              <ListItemText primary="Company" />
            </ListItemButton>
            {/* /configuration */}
            <ListItemButton
              component={Link} // Use Link as the component
              to="/configuration"
              onClick={() => handleMenuItemClickAndClose("Configuration")}
              sx={{
                backgroundColor:
                  activeItem === "Configuration" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Configuration" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <SettingsIcon
                  style={{
                    color: activeItem === "Configuration" ? "#fff" : "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Configuration" />
            </ListItemButton>
            {/* Profile */}
            <ListItemButton
              component={Link} // Use Link as the component
              to="/profile"
              onClick={() => handleMenuItemClickAndClose("Profile")}
              sx={{
                backgroundColor: activeItem === "Profile" ? "#333" : "inherit", // Highlight if active
                color: activeItem === "Profile" ? "#fff" : "inherit", // White text when active
              }}
            >
              <ListItemIcon>
                <AccountBoxIcon
                  style={{ color: activeItem === "Profile" ? "#fff" : "white" }}
                />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </List>
        </Drawer>
      </Box>
    </>
  );
};

export default SideBarRedesign;
