import React, { useState, useEffect, memo } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Typography,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import configServ from "../../../services/config";
import Calculation from "../../../utility/Calculations";
import AddCardSales from "./AddCardSales";
import CardHeadMenu from "../CardHeadMenu/CardHeadMenu";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import JoyModal from "../../Dialog/JoyModal";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useDispatch, useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import AlertDialog from "../../AlertDialog/AlertDialog";

function SalesOrderCard({
  isEdit,
  setIsEdit,
  editData,
  clear,
  setIsChanged,
  isChanged,
}) {
  //#region code
  const dispatch = useDispatch();
  const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [autoGenerate, setAutoGenerate] = useState(true);
  const [approval, setApproval] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const itemListInitial = [
    {
      id: 1,
      itemid: "",
      itemname: "",
      item_code: "",
      packagingunit: "",
      hsn_code: "",
      Taxrate: "",
      qty: "",
      rate: "",
      amount: "",
    },
  ];
  const [itemList, setItemList] = useState(itemListInitial);
  const itemListErrorInitial = [
    {
      itemname: "",
      qty: "",
    },
  ];
  const [itemListError, setItemListError] = useState(itemListErrorInitial);
  const saleOrderInitial = {
    user_id: user_id,
    company_id: company_id,
    _token: "",
    order_no: "",
    partyname: "",
    billtoaddress: "",
    shiptoaddress: "",
    order_type: "",
    order_no_sup: "",
    supplementry_orderno: "",
    validitydate: "",
    sales_order: "",
    salesperson: "",
    pay_terms: "",
    delivery_date: "",
    po_number: "",
    po_date: "",
    totalamount: totalAmount,
    custId: "",
    remarks: "",
    IsApproved: is_super? false : true,
    item: [
      {
        itemname: "",
        itemid: "",
        item_code: "",
        packagingunit: "",
        hsn_code: "",
        Taxrate: "",
        qty: "",
        rate: "",
        amount: "",
      },
    ],
  };
  const [saleOrder, setSaleOrder] = useState(saleOrderInitial);
  const saleOrderErrorInitial = {
    partyname: "",
    billtoaddress: "",
    shiptoaddress: "",
    validitydate: "",
    delivery_date: "",
    po_number: "",
  };
  const [saleOrderError, setSaleOrderError] = useState(saleOrderErrorInitial);
  const shipToAddressesInitial = [
    {
      id: "",
      address: "",
    },
  ];
  const [shipToAddresses, setShipToAddresses] = useState(
    shipToAddressesInitial
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setSaleOrder(editData);
      // assignInvoiceEdit(editData)
      setItemList(editData.items);
      setIsEdit(true);
      fetchShipToAddress(editData.customer_id);
      scrollToTop();
    }
  }, [editData]);

  useEffect(() => {
    fetchCustomerList();
    fetchItemList();
  }, []);

  useEffect(() => {
    calculateTotalAmount();
  }, [itemList]);

  const fetchCustomerList = async () => {
    try {
      const result = await configServ.get_customer({ company_id: company_id });
      setCustomers(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchItemList = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: null,
        search: null,
        per_page: null,
        page: null,
      };
      const res = await configServ.getItemList(dataToSend);
      if (res.status === 200) {
        if (res.data.length > 0) {
          const result = res.data.filter((x) => x.status === 1);
          setItems(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Sale Order code
  // Handle customer change
  const handleCustomerAutocompleteChange = (event) => {
    try {
      const value = event.target.innerText;
      if (value !== null && value !== undefined) {
        if (value.length > 0) {
          handleCustomerInputChange({ target: { name: "partyname", value } });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle customer input
  const handleCustomerInputChange = (event) => {
    try {
      const customerName = event.target.value;
      const selectedCustomer = customers.find(
        (item) => item.contact_name.toLowerCase() === customerName.toLowerCase()
      );

      if (selectedCustomer !== null && selectedCustomer !== undefined) {
        setSaleOrder((prev) => ({
          ...prev, // Copy existing fields
          customer_id: selectedCustomer.id, // Update the specific field with the new value
          partyname: selectedCustomer.contact_name,
          billtoaddress: selectedCustomer.address,
          shiptoaddress: selectedCustomer.address,
        }));
        fetchShipToAddress(selectedCustomer.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Sale Order Customer
  const handleSaleOrderCustomer = (event) => {
    try {
      const { name, value, type, checked } = event.target;
      if (name === "IsApproved") {
        setSaleOrder((prev) => ({
          ...prev, //copy existing field values
          [name]: checked, //update field value
        }));
      } else {
        setSaleOrder((prev) => ({
          ...prev, //copy existing field values
          [name]: value, //update field value
        }));
      }

      // Reset the validation error when the user starts typing
      if (saleOrderError[name]) {
        setSaleOrderError((prevValidationError) => ({
          ...prevValidationError,
          [name]: "",
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Fetch Ship To Address
  const fetchShipToAddress = async (cust_id) => {
    try {
      const result = await configServ.getShipToAddress({ cust_id: cust_id });
      setShipToAddresses(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoGenerateChange = (event) => {
    const autoGenerate = event.target.checked;
    // if(autoGenerate === true){
    //   setSaleOrder((prev)=>({
    //     ...prev,
    //     order_no: ''
    //   }));
    // }
    // else{
    //   setSaleOrder((prev)=>({
    //     ...prev,
    //     order_no: ''
    //   }));
    // }
    setAutoGenerate(autoGenerate);
  };

  const handleApprovalChange = (event) => {
    const isApproved = event.target.checked;
    if (isApproved === true) {
      setSaleOrder((prev) => ({
        ...prev,
        approval_is: true,
      }));
    } else {
      setSaleOrder((prev) => ({
        ...prev,
        approval_is: false,
      }));
    }
    setApproval(isApproved);
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    itemList.forEach((item) => {
      // Parse the amount string to a float value
      const amount = parseFloat(item.amount);
      // Check if the amount is a valid number
      if (!isNaN(amount)) {
        // Add the amount to the total
        totalAmount += amount;
      }
    });
    setTotalAmount(totalAmount.toFixed(2));
  };

  // Itemlist code
  // Handle the Autocomplete's onChange event
 // Handle Autocomplete Change
const handleAutocompleteChange = (event, id) => {
  const value = event?.target?.innerText || ""; // Handle possible undefined or null values
  handleInputChange({ target: { name: "itemname", value } }, id);
};

// Handle Input Changes
const handleInputChange = (event, id) => {
  try {
    const { name, value } = event.target;
    const currentItem = itemList.find((item) => item.id === id);
    let updatedItem;

    if (name === "rate" && currentItem) {
      const totalAmount = Calculation.GetOrderItemAmount(
        currentItem.qty,
        value || 0 // Ensure valid value for calculations
      ); 
      updatedItem = {
        ...currentItem,
        rate: value, // Update rate
        amount: totalAmount.toString(), // Update amount
      };
    } else if (name === "itemname") {
      const selectedItem = items.find(
        (item) => item.item_name.toLowerCase() === value.toLowerCase()
      );
      updatedItem = selectedItem
        ? {
            ...currentItem,
            itemid: selectedItem.id,
            itemname: selectedItem.item_name,
            item_code: selectedItem.item_code,
            packagingunit: selectedItem.packagingunit,
            hsn_code: selectedItem.hsncode,
            qty: "",
            rate: selectedItem.rate_unit,
            Taxrate: selectedItem.tax_rate,
            amount: "0.00",
          }
        : {
            ...currentItem,
            itemid: "",
            itemname: value,
            item_code: "",
            packagingunit: "",
            hsn_code: "",
            discount: "",
            rate: "",
            Taxrate: "",
          };
    } else {
      updatedItem = {
        ...currentItem,
        [name]: value, // Update the specific field (quantity, etc.)
      };
    }

    // Update item list
    const updatedItemList = itemList.map((item) =>
      item.id === id ? updatedItem : item
    );
    setItemList(updatedItemList);
  } catch (error) {
    console.error(error);
  }
};


  // Function to add a new item to the list
  const handleAddItem = () => {
    setItemList([...itemList, { id: itemList.length + 1, name: "", qty: "" }]);
  };

  // Function to delete an item from the list based on its ID
  const handleDeleteItem = (id) => {
    const updatedItemList = itemList.filter((item) => item.id !== id);
    setItemList(updatedItemList);
  };

  // Handle Quantity Change
  const handleQuantityChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        const currentItem = itemList.find((item) => item.id === id);

        if (name === "qty" && currentItem) {
          const totalAmount = Calculation.GetOrderItemAmount(
            value,
            currentItem.rate
          ); // Recalculate amount
          updatedItem = {
            ...currentItem,
            qty: value,
            amount: totalAmount.toString(), // Update amount
          };
        } else {
          updatedItem = {
            ...itemList.find((item) => item.id === id),
            [name]: value,
          };
        }

        const updatedItemList = itemList.map((item) =>
          item.id === id ? updatedItem : item
        );
        setItemList(updatedItemList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Reset
  const handleReset = () => {
    setSaleOrder(saleOrderInitial);
    setSaleOrderError(saleOrderErrorInitial);
    setItemList(itemListInitial);
    setItemListError(itemListErrorInitial);
    clear();
    setIsEdit(false);
  };

  // Save
  const handleSubmit = async () => {
    try {
      dispatch(triggerLoader());

      const saleOrderErrors = validateSaleOrder();
      const itemListErrors = validateItemList();
      if (
        Object.keys(saleOrderErrors).length > 0 ||
        Object.keys(itemListErrors).length > 0
      ) {
        // Handle the errors as needed (e.g., show error messages, prevent saving, etc.)
        return; // Return early if there are errors
      }

      saleOrder.item = await handleSaleOrderItemList();

      if (!isEdit) {
        const res = await configServ.createSaleOrder(saleOrder);
        if (res.success === true) {
          clear();
          handleReset();
          setIsChanged(!isChanged);
          handleOpenDialog(
            "Success",
            res.message || "Sales Order created successfully.",
            "success"
          );
        } else if (res.success === false) {
          handleOpenDialog(
            "Error",
            res.message || "Failed to create sales order.",
            "error"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "Failed to create sales order.",
            "error"
          );
        }
      } else {
        const res = await configServ.editSaleOrder(saleOrder);
        if (res.success === true) {
          clear();
          handleReset();
          setIsChanged(!isChanged);
          handleOpenDialog(
            "Success",
            res.message || "Sales Order updated successfully.",
            "success"
          );
        } else if (res.success === false) {
          handleOpenDialog(
            "Error",
            res.message || "Failed to update sales order.",
            "error"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "Failed to update sales order.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log(error);
      handleOpenDialog("Error", "An unexpected error has occurred.", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const handleSaleOrderItemList = async () => {
    try {
      const saleOrderItems = itemList.map((item) => ({
        itemname: item.itemname,
        itemid: item.itemid,
        item_code: item.item_code,
        packagingunit: item.packagingunit,
        hsn_code: item.hsn_code,
        Taxrate: item.Taxrate,
        qty: item.qty,
        rate: item.rate,
        amount: item.amount,
      }));
      return saleOrderItems;
    } catch (error) {
      console.log(error);
    }
  };

  // Validate the Sale Order fields
  const validateSaleOrder = () => {
    const errors = {};

    // Check if the required fields are empty
    if (!saleOrder.partyname) {
      errors.partyname = "Customer Name is required.";
    }
    if (!saleOrder.billtoaddress) {
      errors.billtoaddress = "Bill To Address is required.";
    }
    if (!saleOrder.shiptoaddress || saleOrder.shiptoaddress === "") {
      errors.shiptoaddress = "ShipTo Address is required.";
    }
    // if (!saleOrder.validitydate) {
    //     errors.validitydate = 'Validity Date is required.';
    // }
    // if (!saleOrder.delivery_date) {
    //     errors.delivery_date = 'PO Date is required.';
    // }
    // if (!saleOrder.po_number) {
    //     errors.po_number = 'PO no is required.';
    // }

    setSaleOrderError(errors);

    return errors;
  };

  // Validate ItemList
  const validateItemList = () => {
    const errors = itemList.map((item) => ({
      itemname: !item.itemname ? "Item Name is required." : "",
      qty: !item.qty ? "Quantity is required." : "",
      // Add additional validations for other item fields as needed...
    }));

    setItemListError(errors);
    const hasErrors = errors.some((error) => error.itemname || error.qty);
    return hasErrors ? errors : {};
  };
  //#endregion

  return (
    <Box>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <motion.div
        style={{
          width: "100%",
          backgroundColor: "#e3f3fc",
          padding: 20,
          borderRadius: "10px",
          border: "none",
          margin: "10px",
        }}
        initial={{ opacity: 0, y: 15 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.1, delay: 0.1 }}
      >
        {/* <Button onClick={abc}>abc</Button> */}
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          style={{ padding: 10 }}
          spacing={1}
        >
          <Grid item xs={12} sm={3} md={3}>
            <Link to="/account-master-entry/customers">
              <Tooltip title={"Add customer"}>
                <PersonAddIcon
                  sx={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "grey",
                    margin: "5px",
                  }}
                  justifyContent="flex-end"
                />
              </Tooltip>
            </Link>
            <Autocomplete
              id="grouped-demo"
              size="small"
              sx={{ backgroundColor: "white" }}
              value={saleOrder.partyname}
              options={customers.map((option) => option.contact_name)}
              onChange={handleCustomerAutocompleteChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={saleOrder.partyname}
                  label="Customer Name"
                  fullWidth
                  onChange={handleCustomerInputChange}
                  error={!!saleOrderError.partyname}
                />
              )}
            />
            {/* <span className='text-danger'>{saleOrderError.partyname}</span>           */}
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <span>{""}</span>
            <TextField
              label="Bill To Address"
              variant="outlined"
              fullWidth
              size="small"
              name="billtoaddress"
              value={saleOrder.billtoaddress}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              error={!!saleOrderError.billtoaddress}
              InputLabelProps={{
                shrink: saleOrder.billtoaddress ? true : false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Ship To Address
              </InputLabel>
              <Select
                sx={{ backgroundColor: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Ship To Address"
                name="shiptoaddress"
                value={saleOrder.shiptoaddress || ""}
                onChange={handleSaleOrderCustomer}
                error={!!saleOrderError.shiptoaddress}
              >
                <MenuItem value={""}>Select</MenuItem>
                {shipToAddresses.map((item) => (
                  <MenuItem key={item.id} value={item.address}>
                    {item.address}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="Auto Generate"
              checked={autoGenerate}
              onChange={handleAutoGenerateChange}
            />
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="Order No"
              placeholder="ORD0001 (Sample)"
              sx={{ backgroundColor: "white" }}
              disabled={autoGenerate}
              name="order_no"
              value={saleOrder.order_no}
              onChange={(e) =>
                setSaleOrder((prev) => ({
                  ...prev,
                  order_no: e.target.value,
                }))
              }
              //InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          style={{ padding: 10 }}
          spacing={1}
        >
          <Grid item xs={12} sm={3} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
              <Select
                sx={{ backgroundColor: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Order Type"
                name="order_type"
                value={saleOrder.order_type}
                onChange={handleSaleOrderCustomer}
              >
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem value={"Primary"}>Primary</MenuItem>
                <MenuItem value={"Trial"}>Trial</MenuItem>
                <MenuItem value={"Supplementry"}>Supplementry</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <TextField
              label="Supplementary Order No"
              variant="outlined"
              fullWidth
              size="small"
              name="supplementry_orderno"
              value={saleOrder.supplementry_orderno}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <TextField
              label="Validity Date"
              variant="outlined"
              type="date"
              fullWidth
              size="small"
              name="validitydate"
              value={saleOrder.validitydate}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              InputLabelProps={{ shrink: true }}
              error={!!saleOrderError.validitydate}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <TextField
              label="Salesperson/Ref. Name"
              variant="outlined"
              fullWidth
              size="small"
              name="salesperson"
              value={saleOrder.salesperson}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          style={{ padding: 10 }}
          spacing={1}
        >
          <Grid item xs={12} sm={2} md={2}>
            <TextField
              label="Pay Terms"
              variant="outlined"
              fullWidth
              size="small"
              name="pay_terms"
              value={saleOrder.pay_terms}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <TextField
              label="PO Date"
              variant="outlined"
              type="date"
              fullWidth
              size="small"
              name="po_date"
              value={saleOrder.po_date}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              InputLabelProps={{ shrink: true }}
              error={!!saleOrderError.delivery_date}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <TextField
              select
              label="Select Document"
              variant="outlined"
              fullWidth
              size="small"
              name="document_type"
              // value={saleOrder.document_type}
              // onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              InputLabelProps={{
                shrink: true, // Ensures label does not overlap
              }}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="quotation">Quotation</MenuItem>
              <MenuItem value="proforma">Proforma</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <TextField
              label="PO No."
              variant="outlined"
              fullWidth
              size="small"
              name="po_number"
              value={saleOrder.po_number}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              error={!!saleOrderError.po_number}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <TextField
              label="Remarks"
              variant="outlined"
              placeholder="Leave a comment here"
              multiline
              rows={2}
              fullWidth
              size="small"
              name="remarks"
              value={saleOrder.remarks}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <FormControlLabel
              disabled={Boolean(!is_super)}
              control={<Checkbox />}
              label="Approval Required"
              name="IsApproved"
              checked={!!saleOrder.IsApproved}
              onChange={handleSaleOrderCustomer}
            />
            <Typography variant="body1">Net Amount(₹ {totalAmount})</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid xs={0} md={8}></Grid>
          <Grid container justifyContent={"center"} xs={6} md={2}>
            <Button
              fullWidth
              onClick={handleReset}
              variant="contained"
              sx={{ minWidth: "15%", mx: 1 }}
              startIcon={<RotateLeftIcon />}
              color={"secondary"}
            >
              Reset
            </Button>
          </Grid>
          <Grid container justifyContent={"center"} xs={6} md={2}>
            <Button
              fullWidth
              onClick={handleSubmit}
              autoFocus
              variant="contained"
              sx={{ minWidth: "15%", mx: 1 }}
              startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
              color={isEdit ? "success" : "primary"}
            >
              {isEdit ? "Update" : "Save"}
            </Button>
          </Grid>
        </Grid>
      </motion.div>

      <AddCardSales
        itemList={itemList}
        handleAutocompleteChange={handleAutocompleteChange}
        handleInputChange={handleInputChange}
        handleAddItem={handleAddItem}
        handleDeleteItem={handleDeleteItem}
        items={items}
        handleQuantityChange={handleQuantityChange}
        errors={itemListError}
      />
    </Box>
  );
}

export default memo(SalesOrderCard);
