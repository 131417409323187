import React, { memo, useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Grid,
    Divider,
} from '@mui/material'
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import configServ from '../../services/config';
import AlertDialog from '../AlertDialog/AlertDialog';
import { MultiSelect } from "react-multi-select-component";
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';

//FUNCTION
const CustomUser = ({ role }) => {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);

    const initialFormData = {
        role: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isEdit, setIsEdit] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [userLoading, setUserLoading] = useState(true);

    const fetchUserListForAdmin = async () => {
        try {
            setUserLoading(true);
            const dataToSend = {
                company_id: company_id,
            }
            const res = await configServ.getUserAdmin(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                if (result && Array.isArray(result)) {
                    const options = result.map((item) => ({
                        label: item.name,  // Assuming `fname` is the first name
                        value: item.id      // Assuming `id` is the customer ID
                    }));
                    setUsers(options);
                }
                else {
                    setUsers([]);
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setUserLoading(false);
        }
    }

    useEffect(() => {
        fetchUserListForAdmin();
    }, [isChanged]);

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent("");
    };

    const handleEdit = (data) => {
        setEditData(data);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const dataToSend = {
            ...formData,
            company_id: company_id,
            user_id: user_id,
            users: selectedUsers,
        };
        try {
            dispatch(triggerLoader());
            const error = await handleFormValidation();
            if (error === false) {
                return false;
            }
            if (!isEdit) {
                const result = await configServ.createAccessMasterUser(dataToSend);
                if (result.status === 200) {
                    setIsChanged(!isChanged);
                    handleOpenDialog(result.message);
                }
                clear();
            }
            else {
                const result = await configServ.updateAccessMasterUser(dataToSend);
                if (result.status === 200) {
                    setIsChanged(!isChanged);
                    handleOpenDialog(result.message);
                }
                clear();
            }
        }
        catch (e) {
            console.log(e);
        }
        finally{
            dispatch(triggerLoader());
        }
    };

    const fetchRoles = async () => {
        try {
            const result = await configServ.getRoles({ "admin_id": user_id })
            if (result.status === 200) {
                setRoleList(result.data);
            }
            else {
                setRoleList([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                ...editData,
                role: editData.user.roles[0]?.id || null,
            }));
            setIsEdit(true);
        }
    }, [editData]);

    const clear = () => {
        setFormData(initialFormData);
        setEditData({});
        setIsEdit(false);
        setValidationErrors({});
        setSelectedUsers([]);
    };

    const handleFormValidation = async () => {
        const errors = {};

        if (!formData.role) {
            errors.role = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />

            <motion.div
                style={{
                    width: "100%",
                    backgroundColor: "#e3f3fc",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: "10px",
                    border: "none",
                }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
            >
                <Divider sx={{ my: 1 }}><b>{role === "" || role === null ? "" : role.toUpperCase()} DETAIL</b></Divider>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <MultiSelect
                            options={users}
                            value={selectedUsers}
                            onChange={setSelectedUsers}
                            labelledBy="Select"
                            isLoading={userLoading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <FormControl fullWidth size="small">
                            <InputLabel error={!!validationErrors.role} htmlFor="role">Role*</InputLabel>
                            <Select
                                value={formData.role || ""}
                                onChange={handleChange}
                                label="Role*"
                                name='role'
                                inputProps={{
                                    name: 'role',
                                    id: 'role',
                                }}
                                sx={{ backgroundColor: 'white' }}
                                error={!!validationErrors.role}
                            >
                                {roleList.map((item) => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid container spacing={2} justifyContent="space-between" px={2}>
                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                <Button
                                    onClick={clear}
                                    variant="contained"
                                    color='secondary'
                                    startIcon={<RotateLeftIcon />}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleSubmit}
                                    autoFocus
                                    variant="contained"
                                    color='primary'
                                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                >
                                    {isEdit ? "Update" : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                </Grid>
            </motion.div>

            {/* <CustomUserTable handleEdit={handleEdit} isChanged={isChanged} /> */}
        </>
    )
}

export default memo(CustomUser);
