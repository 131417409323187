import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import configServ from "../../services/config";
import CurrencyList from "../../Components/List/CurrencyList";
import { Form } from "react-bootstrap";
import {
  Grid,
  RadioGroup,
  TextField,
  Button,
  Input,
  DialogContent,
  DialogTitle,
  DialogActions,
  Dialog,
  InputAdornment,
  Checkbox,
  IconButton,
} from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { Radio } from "@mui/material";
import { FormLabel } from "react-bootstrap";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Stack,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  AddBoxTwoTone,
  AddIcCallTwoTone,
  Visibility,
} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import RegistrationTable from "../../Components/Table/RegistrationTable/RegistrationTable";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import Sessions from "../../Components/Sessions/Sessions";

//FUNCTION
const Registration = () => {
  //#region code
  const dispatch = useDispatch();
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const initialFormData = {
    is_active: true,
    EmailNotification: false,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [validationErrors, setValidationErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [adminList, setAdminList] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [companyImage, setCompanyImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const fileInputRef = useRef(null);
  const subscriptionOptions = ["Yearly", "Monthly", "Trial", "Daily"];
  const [isInvoicePrefix, setIsInvoicePrefix] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [openSession, setOpenSession] = useState(false);
  const [agentType, setAgentType] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState([]);

  const validateGSTNumber = (gstNumber) => {
    // GST number must follow the pattern: 2 digits, 5 letters, 4 digits, 1 letter, 1 alphanumeric, 1 'Z', 1 alphanumeric
    const gstRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z1-9]{1}[Z]{1}[A-Z0-9]{1}$/;
    return gstRegex.test(gstNumber) && gstNumber.length === 15;
  };

  const validatePANNumber = (panNumber) => {
    // PAN number must follow the pattern: 5 letters, 4 digits, 1 letter
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(panNumber) && panNumber.length === 10;
  };

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  const changeHandler = async (e) => {
    try {
      const { name, value } = e.target;
      let errors = { ...validationErrors };

      // GST Number validation and PAN auto-fill
      if (name === "tax") {
        if (value && !validateGSTNumber(value)) {
          errors.gstNumber =
            "GST number must be 15 characters long and include both letters and numbers.";
        } else {
          delete errors.gstNumber;

          if (value) {
            // Extract PAN number from GST number (3rd to 12th characters)
            const panNumber = value.slice(2, 12);
            setFormData((state) => ({
              ...state,
              CompanyPan_tin_no: panNumber,
            }));

            if (!validatePANNumber(panNumber)) {
              errors.panNumber = "Invalid PAN number extracted from GST.";
            } else {
              delete errors.panNumber;
            }
          }
        }
      }

      // PAN Number validation
      if (name === "CompanyPan_tin_no") {
        if (value && !validatePANNumber(value)) {
          errors.panNumber =
            "PAN number must be 10 characters long and include only alphanumeric characters.";
        } else {
          delete errors.panNumber;
        }
      }

      setValidationErrors(errors);

      // Update form data state
      setFormData((state) => ({
        ...state,
        [name]: value,
      }));

      if (name === "country") {
        setFormData((state) => ({
          ...state,
          [name]: value,
        }));
      } else if (name === "State") {
        setFormData((state) => ({
          ...state,
          [name]: value,
        }));
      } else {
        setFormData((state) => ({
          ...state,
          [name]: value,
        }));
      }
      if (name === "country") {
        if (value !== null) {
          const countryName = value;
          const currency = await fetchCurrency(countryName);
          if (currency !== null) {
            const foundCurrency = CurrencyList.find(
              (item) => item.code.toLowerCase() === currency.toLowerCase()
            );
            if (foundCurrency) {
              setFormData((state) => ({
                ...state,
                currency: foundCurrency.code,
              }));
            } else {
              // Handle the case where no matching currency is found
              console.error(
                `No matching currency found for country: ${countryName}`
              );
            }
          }
        }
      }

      // Reset the validation error when the user starts typing
      if (validationErrors[name]) {
        setValidationErrors((prevValidationError) => ({
          ...prevValidationError,
          [name]: "",
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const convertBase64ToFile = (base64String, fileName = "file") => {
    const byteCharacters = atob(base64String.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: "application/octet-stream" });
    file.name = fileName;

    return file;
  };

  const removeDataUrlPrefix = (base64String) => {
    return base64String.split(",")[1];
  };

  const handleChangeFile = async (e) => {
    const { name, files } = e.target;
    const base64Image = await convertToBase64(files[0]);
    // setSelectedImage(removeDataUrlPrefix(base64Image))
    setSelectedImage(base64Image);
  };

  // const handleChangeFile = async (e) => {
  //     const { name, files } = e.target
  //     setSelectedImage(files[0])
  //     console.log(files[0])
  // }

  const handleSubmit = async (e) => {

    e.preventDefault();

    dispatch(triggerLoader());
    let invoicePrefix = "INVEX";
    if (
      formData.InvoicePrefix !== "" &&
      formData.InvoicePrefix !== null &&
      formData.InvoicePrefix !== undefined
    ) {
      invoicePrefix = formData.InvoicePrefix;
    }
    const dataToSend = {
      ...formData,
      InvoicePrefix: invoicePrefix,
      CompanyLogo: selectedImage,
      user_id: user_id,
    };
    try {
      const error = await handleFormValidation();
      if (error === false) {
        scrollToTop();
        return false;
      }
      setValidationErrors({});

      if (!isEdit) {
        const res = await configServ.addCompany(dataToSend);
        if (res.status === 200) {
          handleOpenDialog(
            "Success",
            res.message ||
            "Company registration successful. Please update session and continue.",
            "success"
          );
          clear();
          setIsChanged(!isChanged);
        } else {
          handleOpenDialog("Error", "Company registration failed.", "error");
        }
      } else {
        const res = await configServ.editCompany(dataToSend);
        if (res.status === 200) {
          handleOpenDialog(
            "Success",
            res.message || "Company updated successfully.",
            "success"
          );
          clear();
          setIsChanged(!isChanged);
        } else {
          handleOpenDialog("Error", "Failed to update company.", "error");
        }
      }
    } catch (error) {
      console.log(error);
      handleOpenDialog("Error", "An exception error has occurred.", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const fetchAdmins = async () => {
    const res = await configServ.getAdminList();
    setAdminList(res);
  };

  const fetchCurrency = async (country) => {
    const res = await axios.post(
      "https://countriesnow.space/api/v0.1/countries/currency",
      { country: country }
    );
    if (!res.error) {
      // setCountryList(res.data.data)
      // setFormData((state) => ({
      //     ...state,
      //     currency: res.data.data.currency
      // }))
      return res.data.data.currency;
    }
    return null;
  };

  const fetchCounties = async () => {
    try {
      const res = await configServ.getCountry();
      if (res.status === 200) {
        setCountryList(res.data);
      } else {
        setCountryList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStates = async () => {
    try {
      const countryId = countryList.find(
        (x) => x.country === formData.country
      ).PK;
      const res = await configServ.getState({ country: countryId });
      if (res.status === 200) {
        setStateList(res.data);
      } else {
        setStateList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSessions = async (id) => {
    try {
      const dataToSend = {
        company_id: id,
      };
      const res = await configServ.getSessions(dataToSend);
      if (res.status === 200) {
        setSessions(res.data);
      } else {
        setSessions([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCounties();
    fetchAdmins();
    fetchAgentType();
    fetchSubscriptionType();
  }, []);

  useEffect(() => {
    if (formData.country !== undefined) {
      fetchStates();
    }
  }, [formData.country]);

  const getImageFormatFromBase64 = (base64String) => {
    const mimeRegex = /^data:(image\/\w+);/;
    const matches = base64String.match(mimeRegex);
    return matches ? matches[1] : null;
  };

  const handleEditData = async (data) => {
    if (data.length > 0) {
      await fetchSessions(data[0].id);
      setFormData(data[0]);
      if (data[0].EmailNotification === 0) {
        setFormData((prev) => ({
          ...prev,
          EmailNotification: false,
        }));
      } else if (data[0].EmailNotification === 1) {
        setFormData((prev) => ({
          ...prev,
          EmailNotification: true,
          mail_cc: data[0].mail_config.mail_cc,
          message: data[0].mail_config.message,
        }));
      }
      if (data[0].session !== null) {
        setFormData((prev) => ({
          ...prev,
          session: data[0].session.session,
        }));
      }
      setIsEdit(true);
      setIsCreate(true);
      if (data[0].CompanyLogo !== undefined && data[0].CompanyLogo !== null) {
        setCompanyImage(`${data[0].CompanyLogo}`);
        try {
          const imageFormat = getImageFormatFromBase64(data[0].CompanyLogo);
          const selectedImage = `data:${imageFormat || "image/jpg"};base64,${data[0].CompanyLogo
            }`;
          setSelectedImage(selectedImage);
        } catch (e) {
          console.error("Error processing image:", e);
        }
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const clear = () => {
    setFormData(initialFormData);
    setValidationErrors({});
    setIsEdit(false);
    scrollToTop();
    setSelectedImage(null);
    setCompanyImage(null);
    setIsInvoicePrefix(false);

    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handlePopup = () => {
    setIsCreate(!isCreate);
    clear();
  };

  const handleClose = () => {
    setIsCreate(false);
    clear();
  };

  const handleImageChange = async (event) => {
    setOpenDialog(true);
    setCompanyImage(event.target.files[0]);
    const base64Image = await convertToBase64(event.target.files[0]);
    setSelectedImage(base64Image);
  };

  const handleRemoveImage = () => {
    setCompanyImage(null);
    setSelectedImage(null);
    setOpenDialog(false);

    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handlePreview = () => {
    if (selectedImage === null) {
      setDialogOpen(true);
      setDialogContent("Please choose company logo.");
    } else {
      setOpenDialog(true);
    }
  };

  // Regular expression pattern for validating email addresses
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleFormValidation = async () => {
    const errors = {};
    if (!formData.AdminId) {
      errors.AdminId = "required";
    }
    if (!formData.CompanyName) {
      errors.CompanyName = "required";
    }
    if (!selectedImage) {
      errors.CompanyLogo = "required";
    }
    if (!formData.MobileNo) {
      errors.MobileNo = "required";
    }
    if (!formData.CompanyEmail) {
      errors.CompanyEmail = "required";
    } else if (!emailPattern.test(formData.CompanyEmail)) {
      errors.CompanyEmail = "Invalid Email";
    }
    if (!formData.Address) {
      errors.Address = "required";
    }
    if (!formData.country) {
      errors.country = "required";
    }
    if (!formData.State) {
      errors.State = "required";
    }
    if (!formData.Pincode) {
      errors.Pincode = "required";
    }
    if (!formData.CompanyType) {
      errors.CompanyType = "required";
    }
    if (!formData.FinancialBeginningfrom) {
      errors.FinancialBeginningfrom = "required";
    }
    if (!formData.SubscriptionStatus) {
      errors.SubscriptionStatus = "required";
    }
    if (formData.tax) {
      if (!validateGSTNumber(formData.tax)) {
        errors.gstNumber =
          "Invalid GST number. Must be 15 characters long and formatted correctly.";
      }
    }

    if (formData.CompanyPan_tin_no) {
      if (!validatePANNumber(formData.CompanyPan_tin_no)) {
        errors.panNumber =
          "Invalid PAN number. Must be 10 characters long and formatted correctly.";
      }
    }
    if (!formData.session && isEdit) {
      errors.session = "required";
    }
    if (!isInvoicePrefix && !isEdit) {
      errors.InvoicePrefix = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const checkCompanyPrefix = async () => {
    try {
      const dataToSend = {
        InvoicePrefix: formData.InvoicePrefix,
      };
      const res = await configServ.checkCompanyPrefix(dataToSend);
      if (res.status === 200) {
        setIsInvoicePrefix(true);
      } else {
        setIsInvoicePrefix(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (formData.InvoicePrefix) {
      checkCompanyPrefix();
    }
  }, [formData.InvoicePrefix]);

  const refreshSession = () => {
    fetchSessions(formData.id);
  };

  const fetchAgentType = async () => {
    try {
      const res = await configServ.getAgentType();
      if (res.status === 200) {
        setAgentType(res.data);
      }
      else {
        setAgentType([]);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const fetchSubscriptionType = async () => {
    try {
      const res = await configServ.getSubscriptionType();
      if (res.status === 200) {
        setSubscriptionType(res.data);
      }
      else {
        setSubscriptionType([]);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <Sessions
        id={formData.id}
        sessions={sessions}
        openSession={openSession}
        closeSession={() => setOpenSession(false)}
        refreshSession={refreshSession}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Preview</DialogTitle>
        <DialogContent>
          {companyImage && typeof companyImage === "object" && (
            <img
              alt="not found"
              width="100%"
              src={URL.createObjectURL(companyImage)}
            />
          )}
          {companyImage && typeof companyImage === "string" && (
            <img
              alt="not found"
              width="100%"
              src={`data:image;base64,${companyImage}`}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveImage}>Remove</Button>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <CardHeadMenu
        prefix={"Na"}
        suffix={"Na"}
        title={"COMPANY"}
        handlePopup={handlePopup}
        isEdit={isEdit}
        create={isCreate}
      />

      {isCreate === true && (
        <div
          style={{
            width: "100%",
            backgroundColor: "#e3f3fc",
            padding: 20,
            marginTop: 10,
            borderRadius: "10px",
            border: "none",
          }}
        >
          <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={12} justifyContent={"flex-start"}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                Company Detail
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl
                fullWidth
                size="small"
                error={!!validationErrors.AdminId}
              >
                <InputLabel>Admin*</InputLabel>
                <Select
                  name="AdminId"
                  label="Admin*"
                  value={formData.AdminId || ""}
                  onChange={changeHandler}
                  sx={{ backgroundColor: "white" }}
                  error={!!validationErrors.AdminId}
                >
                  {adminList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Company Name*"
                placeholder="enter company name"
                name="CompanyName"
                onChange={changeHandler}
                value={formData.CompanyName || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                error={!!validationErrors.CompanyName}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Contact No.*"
                placeholder="enter contact no."
                name="MobileNo"
                type="number"
                onChange={changeHandler}
                value={formData.MobileNo || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                error={!!validationErrors.MobileNo}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Email *"
                placeholder="enter email"
                name="CompanyEmail"
                onChange={changeHandler}
                value={formData.CompanyEmail || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "red" }}>
                      {validationErrors.CompanyEmail}
                    </InputAdornment>
                  ),
                }}
                error={!!validationErrors.CompanyEmail}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Address*"
                placeholder="Enter address"
                name="Address"
                onChange={changeHandler}
                value={formData.Address || ""}
                fullWidth
                multiline
                rows={2}
                size="small"
                sx={{ backgroundColor: "white" }}
                error={!!validationErrors.Address}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl
                fullWidth
                size="small"
                error={!!validationErrors.country}
              >
                <InputLabel htmlFor="country">Country*</InputLabel>
                <Select
                  id="country"
                  name="country"
                  value={formData.country || ""}
                  onChange={(event) => {
                    const newCountry = event.target.value;
                    changeHandler({
                      target: {
                        name: "country",
                        value: newCountry === "" ? null : newCountry,
                      },
                    });
                  }}
                  label="Country*"
                  sx={{ backgroundColor: "white" }}
                  error={!!validationErrors.country}
                >
                  {countryList.map((item) => (
                    <MenuItem key={item.country} value={item.country}>
                      {item.country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl
                fullWidth
                size="small"
                error={!!validationErrors.State}
              >
                <InputLabel htmlFor="state">State*</InputLabel>
                <Select
                  id="state"
                  name="State"
                  value={formData.State || ""}
                  onChange={(event) => {
                    const newState = event.target.value;
                    changeHandler({
                      target: {
                        name: "State",
                        value: newState === "" ? null : newState,
                      },
                    });
                  }}
                  label="State*"
                  sx={{ backgroundColor: "white" }}
                  error={!!validationErrors.State}
                >
                  {stateList.map((item) => (
                    <MenuItem key={item.State} value={item.State}>
                      {item.State}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="PIN Code*"
                placeholder="Enter pincode"
                name="Pincode"
                type="number"
                onChange={changeHandler}
                value={formData.Pincode || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                error={!!validationErrors.Pincode}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Label
                  style={
                    validationErrors.CompanyLogo
                      ? { color: "#d32f2f", fontWeight: 700 }
                      : { color: "black", fontWeight: 700 }
                  }
                >
                  Logo*
                </Form.Label>
                <Input
                  type="file"
                  name="myImage"
                  onChange={handleImageChange}
                  accept=".jpg, .gif, .png, .gif, .jpeg"
                  sx={{ marginLeft: "10px", marginBottom: "10px" }}
                  inputRef={fileInputRef}
                />
                <Button
                  variant="outlined"
                  onClick={handlePreview}
                  sx={{
                    marginLeft: "10px",
                    backgroundColor: "white",
                    ":hover": { backgroundColor: "wheat" },
                  }}
                >
                  Preview
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Website"
                placeholder="https://www.exampla.com"
                name="CompanyWebsite"
                type="text"
                onChange={changeHandler}
                value={formData.CompanyWebsite || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  borderRadius: "5px",
                  border: validationErrors.CompanyType
                    ? "1px solid red"
                    : "1px solid transparent", // Set border color based on validation error
                  backgroundColor: "white",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <FormControl
                  component="fieldset"
                  error={validationErrors.CompanyType}
                >
                  <FormLabel
                    component="legend"
                    sx={{ fontWeight: "bolder", marginLeft: "40px" }}
                  >
                    Company Type*
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    row
                    sx={{ marginLeft: "20px" }}
                    name="CompanyType"
                    onChange={changeHandler}
                    value={formData.CompanyType || ""}
                  >
                    <FormControlLabel
                      value="product"
                      control={<Radio size="small" />}
                      label="product"
                    />
                    <FormControlLabel
                      value="service"
                      control={<Radio size="small" />}
                      label="service"
                    />
                    <FormControlLabel
                      disabled
                      value="Both"
                      control={<Radio size="small" />}
                      label="Both"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Subscription Type</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="SubscriptionType"
                  onChange={changeHandler}
                  value={formData.SubscriptionType || ''}
                >
                  {
                    subscriptionType.map((item, index) => (
                      <FormControlLabel key={index} value={item.id} control={<Radio size='small' />} label={item.type} />
                    ))
                  }
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Domain URL"
                placeholder="https://www.exampla.com"
                name="domain_url"
                type="text"
                onChange={changeHandler}
                value={formData.domain_url || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="agent_type">Agent Type</InputLabel>
                <Select
                  id="agent_type"
                  name="Agent Type"
                  value={formData.agent_type || ""}
                  onChange={(event) => {
                    const newState = event.target.value;
                    changeHandler({
                      target: {
                        name: "agent_type",
                        value: newState === "" ? null : newState,
                      },
                    });
                  }}
                  label="Agent Type*"
                  sx={{ backgroundColor: "white" }}
                >
                  <MenuItem key={0} value={''}>
                    {'Select'}
                  </MenuItem>
                  {agentType.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} justifyContent={"flex-start"} mt={2}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                Tax Detail
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Beginning Financial year*"
                type="date"
                name="FinancialBeginningfrom"
                onChange={changeHandler}
                value={formData.FinancialBeginningfrom || ""}
                fullWidth
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{ backgroundColor: "white" }}
                error={!!validationErrors.FinancialBeginningfrom}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {/* <Autocomplete
                    id="currency"
                    options={CurrencyList}
                    getOptionLabel={(item) => (item ? item.code : '')}
                    value={formData.currency}
                    onChange={(_, newValue) => {
                        changeHandler({ target: { name: 'currency', value: newValue === null ? '' : newValue } });
                    }}
                    renderInput={(params) => <TextField {...params} label="Currency"
                        sx={{ backgroundColor: 'white' }} fullWidth size="small"
                        value={formData.currency}
                    />}
                /> */}
              <FormControl fullWidth size="small">
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select
                  id="currency"
                  value={formData.currency || ""}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    changeHandler({
                      target: {
                        name: "currency",
                        value: newValue === "" ? null : newValue,
                      },
                    });
                  }}
                  label="Currency"
                  sx={{ backgroundColor: "white" }}
                >
                  {CurrencyList.map((item) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl
                fullWidth
                size="small"
                error={!!validationErrors.SubscriptionStatus}
              >
                <InputLabel htmlFor="subscription-status">
                  Subscription Status*
                </InputLabel>
                <Select
                  id="subscription-status"
                  name="SubscriptionStatus"
                  value={formData.SubscriptionStatus || ""}
                  onChange={(event) => {
                    const newSubscriptionStatus = event.target.value;
                    changeHandler({
                      target: {
                        name: "SubscriptionStatus",
                        value:
                          newSubscriptionStatus === ""
                            ? null
                            : newSubscriptionStatus,
                      },
                    });
                  }}
                  label="Subscription Status*"
                  sx={{ backgroundColor: "white" }}
                  error={!!validationErrors.SubscriptionStatus}
                >
                  {subscriptionOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="GSTIN/UIN"
                placeholder="Enter GSTIN/UIN"
                name="tax"
                onChange={changeHandler}
                value={formData.tax || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                error={!!validationErrors.gstNumber}
                // helperText={validationErrors.gstNumber}
                inputProps={{ maxLength: 15 }} // Limit input to 15 characters
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Pan No/Tin No"
                placeholder="Enter Pan No/Tin No"
                name="CompanyPan_tin_no"
                onChange={changeHandler}
                value={formData.CompanyPan_tin_no || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                error={!!validationErrors.panNumber}
                //helperText={validationErrors.panNumber}
                inputProps={{ maxLength: 10 }} // Limit input to 10 characters
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                disabled={isEdit}
                label="Invoice Prefix"
                placeholder="INVEX"
                name="InvoicePrefix"
                type="text"
                onChange={changeHandler}
                value={formData.InvoicePrefix || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isInvoicePrefix || isEdit ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </InputAdornment>
                  ),
                }}
                error={!!validationErrors.InvoicePrefix}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="CIN"
                placeholder="Enter CIN"
                name="CIN"
                type="text"
                onChange={changeHandler}
                value={formData.CIN || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="TAN"
                placeholder="Enter TAN"
                name="tan"
                type="text"
                onChange={changeHandler}
                value={formData.tan || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
              />
            </Grid>

            {isEdit === true && (
              <>
                <Grid item xs={12} md={12} justifyContent={"flex-start"} mt={2}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                    Current Session
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!validationErrors.AdminId}
                  >
                    <InputLabel error={!!validationErrors.session}>
                      Session*
                    </InputLabel>
                    <Select
                      name="session"
                      label="Session*"
                      value={formData.session || ""}
                      onChange={changeHandler}
                      sx={{ backgroundColor: "white" }}
                      error={!!validationErrors.session}
                    >
                      {sessions.map((item) => (
                        <MenuItem key={item.session} value={item.session}>
                          {item.session}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <IconButton title="Create Session">
                    <AddBoxTwoTone
                      onClick={() => setOpenSession(true)}
                      sx={{
                        cursor: "pointer",
                        p: 0,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </IconButton>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={12} justifyContent={"flex-start"} mt={2}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                Additional Detail
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Declaration"
                placeholder="Enter declaration"
                name="declaration"
                multiline
                rows={3}
                onChange={changeHandler}
                value={formData.declaration || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Terms & Conditions"
                placeholder="Enter Terms & Conditions"
                name="term_conditions"
                multiline
                rows={3}
                onChange={changeHandler}
                value={formData.term_conditions || ""}
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                name="EmailNotification"
                control={
                  <Checkbox
                    checked={Boolean(formData.EmailNotification) || false}
                  />
                }
                onChange={handleChangeCheckbox}
                label="Send Notification"
                sx={{ mr: "20px" }}
              />
              {/* <Form.Label> <Form.Check onChange={handleChangeCheckbox} name="EmailNotification" checked={formData.EmailNotification} inline aria-label="option 1" size={100} />Send Notification</Form.Label> */}
            </Grid>

            <div hidden={formData.EmailNotification ? false : true}>
              <Stack
                sx={{
                  backgroundColor: "white",
                  padding: 2,
                  mt: 2,
                  borderRadius: 1,
                  gap: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Typography>Email(cc)</Typography>
                    <TextField
                      name="mail_cc"
                      value={formData.mail_cc}
                      onChange={changeHandler}
                      fullWidth
                      size="small"
                      label="Enter address"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>Message</Typography>
                    <TextField
                      name="message"
                      value={formData.message}
                      onChange={changeHandler}
                      fullWidth
                      size="small"
                      multiline
                      label="Leave a comment here"
                      variant="outlined"
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography component={"p"} sx={{ color: "blue" }}>
                      Note:
                      <br />
                      1. If you don't want to send Company Creation/updation
                      mail, uncheck the Send Notification checkbox.
                      <br />
                      2. Email(cc) separate email IDs with a comma (,)
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </div>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              marginTop={"10px"}
              spacing={1}
            >
              <Grid item xs={3} md={8}>
                <FormControlLabel
                  name="status"
                  control={
                    <Checkbox checked={Boolean(formData.is_active) || false} />
                  }
                  onChange={handleChangeCheckbox}
                  label="Status"
                  style={{ marginRight: "20px" }}
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  color={isEdit ? "success" : "primary"}
                  startIcon={isEdit ? <UpdateIcon /> : <HowToRegIcon />}
                >
                  {isEdit ? "Update" : "Register"}
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color={isEdit ? "error" : "secondary"}
                  onClick={isEdit ? handleClose : clear}
                  startIcon={isEdit ? <CloseIcon /> : <UpdateIcon />}
                >
                  {isEdit ? "Close" : "Reset"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      <RegistrationTable
        handleEditData={handleEditData}
        isChanged={isChanged}
      />
    </>
  );
};
export default Registration;
