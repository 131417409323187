import React, { useState, useEffect, useRef } from "react";
import { Fab, Zoom, Tooltip, Typography, useTheme, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import { Link, useLocation } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { rSetShowFab } from "../../redux/reducers/GlobalVariables";
import { useDispatch } from "react-redux"; // Import useDispatch

const FloatingActionButton = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const theme = useTheme();
  const menuRef = useRef(null);

  const dispatch = useDispatch(); // Initialize dispatch
  const location = useLocation(); // Get current location

  const handleFabClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  useEffect(() => {
    setOpenSubMenu(false);
    dispatch(rSetShowFab(true)); // Dispatch action when location changes
  }, [location, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenSubMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box ref={menuRef} sx={fabContainerStyle}>
      {/* Submenu that appears on clicking FAB */}
      <Zoom in={openSubMenu}>
        <Box sx={subMenuStyle}>
          <Tooltip title="Sales Order" arrow placement="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link to="/sales-order" style={fabLinkStyle}>
                <Fab color="primary" size="small" sx={fabButtonStyle}>
                  <BorderClearIcon sx={iconStyle} />
                </Fab>
              </Link>
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                Sales Order
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Invoice" arrow placement="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link to="/invoice" style={fabLinkStyle}>
                <Fab color="primary" size="small" sx={fabButtonStyle}>
                  <InsertDriveFileIcon sx={iconStyle} />
                </Fab>
              </Link>
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                Invoice
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title="Stock Update" arrow placement="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link to="/transaction/stockUpdate" style={fabLinkStyle}>
                <Fab color="primary" size="small" sx={fabButtonStyle}>
                  <ShowChartIcon sx={iconStyle} />
                </Fab>
              </Link>
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                Stock Update
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Cash Voucher" arrow placement="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link to="/transaction/create-vouchers" style={fabLinkStyle}>
                <Fab color="primary" size="small" sx={fabButtonStyle}>
                  <MonetizationOnIcon sx={iconStyle} />
                </Fab>
              </Link>
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                Cash Voucher
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </Zoom>

      {/* Main FAB button */}
      <Fab
        color="secondary"
        onClick={handleFabClick}
        sx={{ ...fabStyle, ...fabButtonStyle }}
      >
        {openSubMenu ? (
          <CloseIcon sx={iconStyle} />
        ) : (
          <AddIcon sx={iconStyle} />
        )}
      </Fab>
    </Box>
  );
};

// Enhanced styles
const fabContainerStyle = {
  position: "fixed",
  bottom: 25,
  right: 25,
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // Center align the submenu with the main button
  zIndex: 9000,
};

const subMenuStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: 3,
  alignItems: "center", // Center submenu items
  gap: 2, // More spacing for a cleaner look
  "& > *": {
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)", // Slightly enlarge on hover
    },
  },
};

const fabStyle = {
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)", // Softer shadow
  transition: "background-color 0.3s ease", // Smoother transition
};

const fabButtonStyle = {
  borderRadius: "50%", // Ensure all buttons are fully rounded
};

const fabLinkStyle = {
  textDecoration: "none",
};

const iconStyle = {
  fontSize: "1.2rem", // Slightly smaller icons for a professional look
};

export default FloatingActionButton;
