import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";
import configServ from "../../services/config";

const SupportButton = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please enter your email.");
      return;
    }

    if (!comment) {
      toast.error("Please enter your message.");
      return;
    }

    const data = { email, comment };
    
    setLoading(true); // Start loading

    try {
      const res = await configServ.sendSupportMail(data);

      if (res.status === 200) {
        toast.success("Mail sent successfully!");
        setEmail(""); // Clear email field
        setComment(""); // Clear comment field
        setOpen(false); // Close the dialog
      } else {
        toast.error("Failed to send mail. Please try again.");
      }
    } catch (error) {
      toast.error("Error sending mail. Please try again later.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <Button
        variant="contained"
        size="large"
        sx={{
          maxWidth: "10rem",
          margin: "10px",
          backgroundColor: "#4caf50",
          color: "#fff",
          "&:hover": { backgroundColor: "#45a049" },
          borderRadius: "25px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          padding: "10px 20px",
        }}
        onClick={handleClickOpen}
      >
        <SupportAgentIcon sx={{ marginRight: "8px" }} />
        Support
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="support-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: "#1976d2",
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            borderBottom: "1px solid #ccc",
          }}
          id="support-dialog-title"
        >
          Support
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            fullWidth
            label="Email Address"
            type="email"
            variant="outlined"
            margin="normal"
            onChange={(e) => setEmail(e.target.value)}
            required
            value={email}
          />
          <TextField
            fullWidth
            label="Your Message"
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            onChange={(e) => setComment(e.target.value)}
            required
            value={comment}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", p: 2 }}>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={handleSubmit}
            sx={{
              borderRadius: "20px",
              padding: "8px 16px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Send to Us
          </Button>
        </DialogActions>
      </Dialog>

      {/* Backdrop Loader */}
      <Backdrop
        sx={{ color: "#fff", zIndex: 1301 }} // Increase zIndex to be above dialog
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SupportButton;
