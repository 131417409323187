import { combineReducers } from '@reduxjs/toolkit';

// Your slices or reducers from @reduxjs/toolkit
import expanderReducer from './reducers/SideExpand'
import loaderReducer from './reducers/LoaderTrigger'
import userReducer from './reducers/userData'
import cartReducer from './reducers/Cart'
import adminReducer from './reducers/Admin'
import GlobalVariablesReducer from './reducers/GlobalVariables'


// Combine reducers into a root reducer
const rootReducer = combineReducers({
  sideExpand: expanderReducer,
  loader: loaderReducer,
  user: userReducer,
  cart: cartReducer,
  admin: adminReducer,
  GlobalVariables: GlobalVariablesReducer,

  // Add other reducers here
});

export default rootReducer;
