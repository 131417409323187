import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import CreatePermission from './CreatePermission'

const Permissions = ({open, handleClose}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Permissions</DialogTitle>
        <DialogContent>
            <CreatePermission />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Permissions
