import React, { memo, useState } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions, Container,
    Box, Typography, IconButton, Grid, TextField
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CompanyPopup = ({ open, handleClose, data }) => {

    const [copiedPAT, setCopiedPAT] = useState(false);
    const [copiedName, setCopiedName] = useState(false);
    const [copiedMobile, setCopiedMobile] = useState(false);
    const [copiedEmail, setCopiedEmail] = useState(false);
    const [copiedURL, setCopiedURL] = useState(false);

    const handleCopy = (value, setCopied) => {
        navigator.clipboard.writeText(value);
        setCopied(true);
    };

    return (
        <Container maxWidth="sm">
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex={1} textAlign="center">
                            <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                                {data ? data.CompanyName : 'No data found.'}
                            </Typography>
                        </Box>
                        <Box position="absolute" right={16}>
                            <IconButton onClick={handleClose}>
                                <DisabledByDefaultIcon color="error" />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} mt={2}>

                        {/* CompanyName */}
                        <Grid item xs={10} md={5}>
                            <TextField
                                size='small'
                                fullWidth
                                label='Company Name'
                                sx={{ marginRight: 1 }}
                                value={data ? data.CompanyName : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton
                                size="small"
                                onClick={() => handleCopy(data ? data.CompanyName : '', setCopiedName)}
                                aria-label="copy Company Name"
                            >
                                {copiedName ? (
                                    <CheckCircleIcon fontSize="small" color="success" />
                                ) : (
                                    <ContentCopyIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Grid>

                        {/* MobileNo */}
                        <Grid item xs={10} md={5}>
                            <TextField
                                size='small'
                                fullWidth
                                label='Mobile No'
                                sx={{ marginRight: 1 }}
                                value={data ? data.MobileNo : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton
                                size="small"
                                onClick={() => handleCopy(data ? data.MobileNo : '', setCopiedMobile)}
                                aria-label="copy Mobile No"
                            >
                                {copiedMobile ? (
                                    <CheckCircleIcon fontSize="small" color="success" />
                                ) : (
                                    <ContentCopyIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Grid>

                        {/* CompanyEmail */}
                        <Grid item xs={10} md={5}>
                            <TextField
                                size='small'
                                fullWidth
                                label='Email'
                                sx={{ marginRight: 1 }}
                                value={data ? data.CompanyEmail : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton
                                size="small"
                                onClick={() => handleCopy(data ? data.CompanyEmail : '', setCopiedEmail)}
                                aria-label="copy Company Email"
                            >
                                {copiedEmail ? (
                                    <CheckCircleIcon fontSize="small" color="success" />
                                ) : (
                                    <ContentCopyIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Grid>

                        {/* CompanyKey */}
                        <Grid item xs={10} md={5}>
                            <TextField
                                size='small'
                                fullWidth
                                label='Key'
                                sx={{ marginRight: 1 }}
                                value={data ? (data.CompanyKey ?? '') : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton
                                size="small"
                                onClick={() => handleCopy(data ? (data.CompanyKey ?? '') : '', setCopiedPAT)}
                                aria-label="copy PAT"
                            >
                                {copiedPAT ? (
                                    <CheckCircleIcon fontSize="small" color="success" />
                                ) : (
                                    <ContentCopyIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Grid>

                        {/* Domain URL */}
                        <Grid item xs={10} md={5}>
                            <TextField
                                size='small'
                                fullWidth
                                label='Domain URL'
                                sx={{ marginRight: 1 }}
                                value={data ? (data.domain_url ?? '') : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton
                                size="small"
                                onClick={() => handleCopy(data ? (data.domain_url ?? '') : '', setCopiedURL)}
                                aria-label="copy PAT"
                            >
                                {copiedURL ? (
                                    <CheckCircleIcon fontSize="small" color="success" />
                                ) : (
                                    <ContentCopyIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default memo(CompanyPopup);
